import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ConfigProvider, FloatButton, Layout, Menu } from 'antd';
import { LogoutOutlined, AppstoreOutlined, ShopOutlined, UserAddOutlined, DollarOutlined, UserOutlined } from '@ant-design/icons';
const { Content, Sider } = Layout;

export default function MainLayout() {
    let navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    return <>
        <ConfigProvider
            theme={{
                components: {
                    Layout: {
                        siderBg: '#fff',
                        triggerColor: '#000',
                        darkItemSelectedBg: "#000",
                        itemActiveBg: "#000",
                        itemSelectedBg: "#000"
                    },
                    Menu: {
                        itemSelectedBg: "#DA0000 ",
                        itemSelectedColor: '#fff'
                    },
                },
            }}
        >
            <Layout className='min-vh-100'>

                <Sider
                    className='bg-white'
                    trigger={null}
                    breakpoint="lg"
                    collapsedWidth="0"
                    onBreakpoint={(broken) => {
                        console.log(broken);
                    }}
                    onCollapse={(collapsed, type) => {
                        console.log(collapsed, type);
                    }}
                >
                    {/* <div className="demo-logo-vertical" /> */}
                    <div className="w-100 py-4 d-flex align-items-center justify-content-center">
                        <span className=' col-5 d-flex justify-content-center pointer rounded-circle overflow-hidden'>
                            <img src={require("./logo.jpg")} alt="" className='w-100' />
                        </span>
                    </div>
                    <Menu
                        className='position-relative'
                        // theme="dark"
                        mode="inline"
                        defaultSelectedKeys={location.pathname.substring(7)}

                    >
                        <Menu.Item onClick={() => { navigate('suppliers') }} key="suppliers" icon={<UserAddOutlined  className='fs-6'/>}>
                            <span> الموردين</span>
                        </Menu.Item>
                        <Menu.Item onClick={() => { navigate('categories') }} key="categories" icon={<AppstoreOutlined  className='fs-6'/>}>
                            <span>فئات المنتجات</span>
                        </Menu.Item>
                        <Menu.Item onClick={() => { navigate('stores') }} key="stores" icon={<ShopOutlined  className='fs-6'/>}>
                            <span>المخازن</span>
                        </Menu.Item>
                        <Menu.Item onClick={() => { navigate('employees') }} key="employees" icon={<UserOutlined  className='fs-6'/>}>
                            <span>الموظفين</span>
                        </Menu.Item>
                        <Menu.Item onClick={() => { navigate('technicians') }} key="technicians" icon={<UserOutlined  className='fs-6'/>}>
                            <span>الفنيين</span>
                        </Menu.Item>
                        {/* <Menu.Item onClick={() => { navigate('taxes') }} key="taxes" icon={<DollarOutlined  className='fs-6'/>}>
                            <span>التخفيضات والضرائب</span>
                        </Menu.Item> */}
                        <Menu.Item key="coupons" onClick={() => { navigate('coupons') }} icon={<i className='fa-solid fa-hashtag fs-6' />}>
                            <span>ألكوبونات</span>
                        </Menu.Item>
                        <Menu.Item onClick={() => { navigate('/login') }} key="login" icon={<LogoutOutlined  className='fs-6 text-danger'/>}>
                            <span>تسجيل خروج</span>
                        </Menu.Item>
                        {/* <div className='position-absolute w-100 bottom-customized-logout'>
                            <div className="w-100 text-center">
                                <span className='fs-5 text-violet pointer' onClick={()=>{navigate('/login')}}>
                                    <i className="fa-solid fa-right-to-bracket"></i> Logout</span>
                            </div>
                        </div> */}
                    </Menu>
                </Sider>
                <ConfigProvider
                    theme={{
                        components: {
                            Layout: {
                                headerHeight: 'auto',
                            },
                        },
                    }}
                >
                    <Layout>

                        <Content
                            className='w-100 overflow-hidden'
                            style={{
                                backgroundColor: "#F8F8F8"
                            }}
                        >

                            <Outlet></Outlet>

                        </Content>

                    </Layout>
                </ConfigProvider>

            </Layout>
        </ConfigProvider>
        <FloatButton.BackTop className='bg-red text-warning' />
    </>
}
