import React from 'react';
import CasherNavbar from "../Navbar/CasherNavbar";
import { Outlet } from 'react-router-dom';
const CasherLayout = () => {
    return (
        <div className='pattern'>
            <CasherNavbar/>
            <Outlet/>
        </div>
    );
}

export default CasherLayout;
