import { Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

export default function Login({ baseUrl }) {
    let navigate = useNavigate()
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        localStorage.clear();

    }, [])


    const onFinish = (values) => {
        sendDataToLogin(values);
    };

    async function sendDataToLogin(values) {
        setIsLoading(true)
        await axios.post(`${baseUrl}/api/User/Login`, values)
            .then((response) => {
                console.log('login', response);
                if (response.data.role === 0) {
                    localStorage.setItem('adminToken', response.data.token)
                    navigate('/admin')
                }
                else if (response.data.role === 1) {
                    localStorage.setItem('KeeperToken', response.data.token)
                    navigate('/keeper')
                }
                else if (response.data.role === 2) {
                    localStorage.setItem('casherToken', response.data.token)
                    navigate('/casher')
                }
                setIsLoading(false)
            }).catch((error) => {
                console.log(error)
                if (error?.response?.status === 401)
                    setError("تأكد من صحة البيانات");
                setIsLoading(false)
            })
    }



    return <>
        <div className="container-fluid d-flex justify-content-center align-items-center vh-100 bg-img position-relative ">
            <div className='position-absolute  rounded-circle overflow-hidden col-1 shadow' style={{ right: 25, top: 25 }}>
                <img src={require("./logo.jpg")} alt="" className='w-100' />
            </div>
            {/* The content half */}
            <div className="login d-flex align-items-center py-5 card rounded-4 shadow col-4">
                {/* Demo content */}
                <div className="w-75">
                    <h3 className='text-center fw-bold mb-3'><span className='text-red '>إسكندر</span> للأدوات الصحيه</h3>
                    <p className="text-end mt-2 mb-2">قم بتسجيل الدخول </p>
                    <Form onFinish={onFinish} initialValues={{ remember: false }} className='d-flex flex-column gap-3'>
                        <div className="form-group ">
                            <div>
                                {error ? (
                                    <div className="alert alert-danger fs-6 text-center">{error}</div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <Form.Item
                                name="email"
                                className="custom-form-item"
                                rules={[
                                    {
                                        required: true,
                                        message: 'قم بأدخال اسم المستخدم',
                                    },
                                    
                                ]}
                                validateTrigger="onBlur"
                            >
                                <Input
                                    size="large"
              
                                    className=''
                                    placeholder="أسم المستخدم" prefix={<i className="fa-regular text-secondary fa-user ms-2"></i>} onChange={() => setError('')} />
                            </Form.Item>
                        </div>
                        <div className="form-group">
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'قم بأدخال الرقم السري' }]}
                                validateTrigger="onBlur"
                            >
                                <Input.Password size="large" placeholder="كلمة السر" prefix={<i className="fa-solid text-secondary fa-lock ms-2"></i>} onChange={() => setError('')} />
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <button className='w-100 btn-red btn text-white py-2 fw-bold' type="primary" htmlType="submit">
                                {isLoading ? "جار التحميل. .." : "تسجيل الدخول"}
                            </button>
                        </Form.Item>
                      
                    </Form>
                </div>
            </div>

        </div>


    </>
}
