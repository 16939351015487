import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Modal, Pagination, Skeleton, notification, message, Popconfirm, Button, Input } from "antd";
import useDocumentTitle from '../../Customized Hooks/useDocumentTitle';

export default function Suppliers({ baseUrl }) {
    useDocumentTitle('الموردين')
    const [Phone, setphone] = useState('');
    const [current, setCurrent] = useState(1);
    const [numofPages, setnumofPages] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [Suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpenForEdit, setIsModalOpenForEdit] = useState(false);
    const [SupplierId, setSuppliersId] = useState(null);
    const [isModalOpenForAdd, setIsModalOpenForAdd] = useState(false);
    const [SupplierName, setSupplierName] = useState('');
    const [errorName, setErrorName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [sending, setsending] = useState(false);
    useEffect(() => {
        getSuppliers()
    }, [current]);

    async function getSuppliers() {
        setLoading(true)
        await axios.get(`${baseUrl}/api/Suppliers/GetAll`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setLoading(false)
                console.log('suppliers res', response);
                console.log('Suppliers', response.data.suppliers);
                setSuppliers(response.data.suppliers);
                // setnumofPages(response.data.paginationResult.numberOfPages)
                // if (response.data.paginationResult.numberOfPages < current) {
                //     setCurrent(pre => pre - 1)
                // }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error);

            })
    }


    async function AddSupplier(name, phone) {
        setsending(true)
        let data = {
            "name": name,
            "phone": phone
        }


        await axios.post(`${baseUrl}/api/Suppliers`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                console.log('Supplier', response);
                setsending(false)
                setIsModalOpenForAdd(false);
                openNotificationWithIcon(
                    'success',
                    "تم بنجاج",
                    "تمت اضافة مورد بنجاح."
                );
                getSuppliers();
                setSupplierName("");
            })
            .catch((error) => {
                setsending(false)
                console.log(error);

            })
    }

    async function EditSupplier(name, phone) {
        setsending(true)

        let data = {
            "code": SupplierId,
            "name": name,
            "phone": phone
        }
        await axios.put(`${baseUrl}/api/Suppliers`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setsending(false)

                setIsModalOpenForEdit(false);
                setErrorName(null);
                console.log('edit Supplier response : ', response);
                setSuppliers(response.data.SupplierLists);
                openNotificationWithIcon(
                    "success",
                    "Edited Successfully",
                    "Supplier Edited successfully."
                );
                getSuppliers()
            }).catch((error) => {
                setsending(false)

                console.log(error);

            })
    }

    const showEditModal = (supplier) => {
        console.log(supplier);
        setSuppliersId(supplier.id)
        setSupplierName(supplier.name)
        setphone(supplier.phone)
        setIsModalOpenForEdit(true)
    };

    const handleEditOk = () => {
        const trimmedSupplierName = SupplierName.trim();
        const trimmedphone = Phone.trim();

        if (trimmedSupplierName === "" || trimmedSupplierName === null) {
            setErrorName("من فضلك ادخل اسم المورد !");
            return;
        }

        if (trimmedphone === "" || trimmedphone === null) {
            setErrorName("من فضلك ادخل رقم المورد !");
            return;
        }

        else if (!trimmedphone.match('^01[0-2,5]{1}[0-9]{8}$')) {
            setErrorName("تأكد من صحة الرقم");
            return;
        }

        else {
            EditSupplier(trimmedSupplierName, trimmedphone);
            setErrorName(null);

        }
    };

    const handleEditCancel = () => {
        setSupplierName('');
        setphone('');
        setphone('');
        setSuppliersId(null);
        setErrorName(null);
        setIsModalOpenForEdit(false);
    };


    const showAddModal = () => {
        setIsModalOpenForAdd(true);
    };

    function PaginationChange(e) {
        setCurrent(e)
    }

    const handleAddOk = () => {
        const trimmedSupplierName = SupplierName.trim();
        const trimmedphone = Phone.trim();

        if (trimmedSupplierName === "" || trimmedSupplierName === null) {
            setErrorName("من فضلك ادخل اسم المورد !");
            return;
        }

        if (trimmedphone === "" || trimmedphone === null) {
            setErrorName("من فضلك ادخل رقم المورد !");
            return;
        }

        else if (!trimmedphone.match('^01[0-2,5]{1}[0-9]{8}$')) {
            setErrorName("تأكد من صحة الرقم");
            return;
        }

        else {
            AddSupplier(trimmedSupplierName, trimmedphone);
            setErrorName(null);

        }
    };


    const handleAddCancel = () => {
        setSupplierName('');
        setphone('');
        setErrorName(null);
        setIsModalOpenForAdd(false);
    };


    async function DeleteSupplier(SupplierId) {
  
        await axios.delete(`${baseUrl}/api/Suppliers?Code=${SupplierId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                openNotificationWithIcon(
                    "success",
                    "تم حذف المورد"
                                );
                getSuppliers()
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage(error?.response?.data?.message)
            })
    }

    function openNotificationWithIcon(type, message, description) {
        api[type]({
            message: message,
            description: description,
        });
    }

    const cancel = (e) => {
        console.log(e);
        message.error("Click on No");
    };

    return <>
        {contextHolder}
        <div className='container-fluid d-flex justify-content-center align-items-center'>


            <div className="mx-auto py-4 w-75" >
                <div className="d-flex justify-content-between ms-auto my-3">
                    <div className='w-50'>
                        <Input className='rounded-5' size='large' placeholder='ابحث عن مورد' prefix={<i className='fa fa-search text-secondary'/>} />
                    </div>
                    <button onClick={showAddModal} className="btn btn-red rounded-5   px-3 py-2"><i className="fa-solid fa-plus"></i> أضف مورد</button>
                </div>
                {loading ? (
                    <>
                        <div>
                            <table className="table table-bordered custom-table">
                                <thead>
                                    <tr className=''>
                                        <th className=''> أسم المورد</th>
                                        <th className=''> رقم الهاتف</th>
                                        <th className='text-center  col-2'>حذف / تعديل</th>
                                    </tr>
                                </thead>
                            </table>
                            <div className="row">
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) :
                    (
                        <div className="d-flex justify-content-center align-items-center">
                            <table className="table table-bordered custom-table">
                                <thead>
                                    <tr className=''>
                                        <th className=''> أسم المورد</th>
                                        <th className=''> رقم الهاتف</th>
                                        <th className='text-center  col-2'>حذف / تعديل</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {Suppliers?.map((Supplier, index) => (
                                        <tr key={index} className=''>
                                            <td className=''>
                                                <h6>{Supplier.name}</h6>
                                            </td>
                                            <td className=''>
                                                <h6>{Supplier.phone}</h6>
                                            </td>
                                         
                                            <td className='text-center col-2'>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="حذف هذا المورد"
                                                    description="هل ان بالتأكيد تريد حذف هذا المورد ؟"
                                                    onConfirm={() => DeleteSupplier(Supplier.id)}
                                                    onCancel={cancel}
                                                    okText="نعم"
                                                    cancelText="لا"
                                                >
                                                    <Button type="link" className="" danger>
                                                        <i className='fa fa-trash fa-lg' />
                                                    </Button>
                                                </Popconfirm>
                                                <Button type='link' onClick={() => showEditModal(Supplier)}><i className='fa fa-edit fa-lg' /></Button>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                <div className='d-flex justify-content-end'>

                    <Pagination current={current} onChange={PaginationChange} pageSize={5} total={numofPages ? numofPages * 5 : 0} showSizeChanger={false} />
                </div>
                {errorMessage !== null && <div className='d-flex justify-content-center'>
                    <Alert className='' closable type='error' message={errorMessage}></Alert>
                </div>}
            </div>

        </div>


        <Modal title={<h5 className=' fw-bold'><i className='fa fa-edit text-primary' /> تعديل بيانات المورد</h5>} open={isModalOpenForEdit} onOk={handleEditOk} confirmLoading={sending} onCancel={handleEditCancel} okText="تعديل">
            <div className='my-4'>
                <label htmlFor="SupplierNameInput" className='fw-bold'>أسم المورد</label>
                <input
                    type="text"
                    className="form-control mb-3 mt-2"
                    id="SupplierNameInput"
                    name="SupplierName"
                    value={SupplierName}
                    onChange={(e) => setSupplierName(e.target.value)}
                    placeholder='أدخل أسم المورد'
                />
                <label htmlFor="phone" className='fw-bold'>رقم الهاتف</label>

                <input
                    type="text"
                    className="form-control mb-3 mt-2"
                    id="phone"
                    name="SupplierName"
                    value={Phone}
                    onChange={(e) => setphone(e.target.value)}
                    placeholder="أدخل رقم الهاتف"
                />
            </div>
            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" />
            )}
        </Modal>




        <Modal title={<h5 className='font-inter fw-bold'>أضافة مورد</h5>} open={isModalOpenForAdd} confirmLoading={sending} onOk={handleAddOk} onCancel={handleAddCancel} okText="أضافه" cancelText='الغاء' >
            <div className='my-4'>
                <label htmlFor="SupplierNameInput" className='fw-bold'>أسم المورد</label>
                <input
                    type="text"
                    className="form-control mb-3 mt-2"
                    id="SupplierNameInput"
                    name="SupplierName"
                    value={SupplierName}
                    onChange={(e) => setSupplierName(e.target.value)}
                    placeholder='أدخل أسم المورد'
                />
                <label htmlFor="phone" className='fw-bold'>رقم الهاتف</label>

                <input
                    type="text"
                    className="form-control mb-3 mt-2"
                    id="phone"
                    name="SupplierName"
                    value={Phone}
                    onChange={(e) => setphone(e.target.value)}
                    placeholder="أدخل رقم الهاتف"
                />
            </div>
            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" className='mt-2' />
            )}
        </Modal>
    </>

} 