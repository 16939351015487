import React from 'react'

import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../Customized Hooks/useDocumentTitle';
import Navbar from '../Navigation/Navbar/Navbar';

export default function KeeperHome({ baseUrl }) {
    useDocumentTitle('keeper')
    const navigate = useNavigate()

  return <>
      <Navbar
          baseUrl={baseUrl}
      />
      <div className='min-vh-100 d-flex justify-content-center align-items-center container '>

          <div className='row home  justify-content-center align-items-center gap-5'>
             
              <div className='d-flex justify-content-center gap-5'>
        
                  <div className='card  d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => { navigate('/keeper/needs') }}>
                      <i class="fa-regular fa-rectangle-list  fs-1"></i>
                      <h4 className='fw-bold'>النواقص</h4>
                  </div>
                  <div className='card  d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => { navigate('/keeper/products') }}>
                      <i class="fa-solid fa-boxes-packing fs-1"></i>
                      <h4 className='fw-bold'> أدارة المنتجات </h4>
                  </div>
              </div>
              <div className=' col-8 btn btn-danger d-flex  justify-content-center align-items-center gap-2 p-0 shadow-sm py-2 rounded-4' onClick={() => { navigate('/login') }}>
                  <h4 className='fw-bold'> تسجيل خروج</h4>
                  <LogoutOutlined className='fs-4 ' />
              </div>

          </div>

      </div>
  </>
}
