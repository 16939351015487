import React from 'react';
import { LogoutOutlined, UserAddOutlined, ShopOutlined, ToolOutlined, DollarOutlined, UserOutlined, AppstoreOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';


const Home = () => {
const navigate = useNavigate()
    return <>
        <div className='min-vh-100 d-flex justify-content-center align-items-center container '>
            <div className='row home  justify-content-center align-items-center gap-5'>

                <div className='d-flex justify-content-center gap-5'>
                    <div className='card d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={()=>{navigate('suppliers')}}>
                        <UserAddOutlined className='fs-1 ' />
                        <h4 className='fw-bold'>الموردين</h4>
                    </div>
                    <div className='card  d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={()=>{navigate('categories')}}>
                        <AppstoreOutlined className='fs-1 ' />
                        <h4 className='fw-bold'>فئات المنتجات</h4>
                    </div>
                    <div className='card  d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={()=>{navigate('stores')}}>
                        <ShopOutlined className='fs-1 ' />
                        <h4 className='fw-bold'> المخازن</h4>
                    </div>
                </div>

                <div className='d-flex justify-content-center gap-5'>
                    <div className='card  d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => { navigate('employees')}}>
                    <UserOutlined className='fs-1 ' />
                    <h4 className='fw-bold'> الموظفين</h4>
                </div>
                    <div className='card  d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => { navigate('technicians')}}>
                    <ToolOutlined className='fs-1 ' />
                    <h4 className='fw-bold'> الفنيين</h4>
                </div>
                    <div className='card  d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => { navigate('taxes')}}>
                    <DollarOutlined className='fs-1 ' />
                    <h4 className='fw-bold'> التخفيضات والضرائب</h4>
                </div>
                </div>
                <div className='btn btn-danger col-8 d-flex  justify-content-center align-items-center gap-2 p-0 shadow-sm py-2 rounded-4' onClick={() => { navigate('/login') }}>
                    <h4 className='fw-bold'> تسجيل خروج</h4>
                    <LogoutOutlined className='fs-4 ' />
                </div>
            </div>



        </div>
    </>
}

export default Home;
