import { Alert, Button, Card, Modal, Select, Spin } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function Points({ baseUrl }) {
    const [technicians, settechnicians] = useState();
    const [technicianID, settechnicianID] = useState();
    const [Loading, setLoading] = useState();
    const [Points, setPoints] = useState();

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    async function getTechnicians() {
        await axios.get(`${baseUrl}/api/Technicians/GetAll`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('Technicians res', response);
                settechnicians(response.data.technicians)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error);

            })
    }


    useEffect(() => {
        getTechnicians();
    }, []);

    useEffect(() => {
        getPoints()
    }, [technicianID]);

    async function getPoints() {
        setLoading(true)
        await axios.get(`${baseUrl}/api/Coupon/GetTechnicalCoupons?&TechnicalId=${technicianID}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('Statments res', response);
                setPoints(response.data)
                setLoading(false)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error);

            })
    }





    return (
        <div className='container'>
            {Loading ? <Spin fullscreen /> : ''}
            <div className="row align-items-end  w-100 p-3 mt-4  m-0">
                <span className="col-4">
                    <label htmlFor="" className='fw-bold ms-2 '>أسم الفني:</label><br />
                    <Select optionFilterProp="children"
                        filterOption={filterOption}
                        showSearch options={technicians?.map((item) => { return { label: item.name, value: item.code } })}
                        placeholder='أسم الفني' className='w-75' onChange={(e) => { settechnicianID(e) }}
                        size="large"
                        onClear={() => { settechnicianID(); setPoints() }}
                        allowClear
                    />
                </span>
                <span className="col card  py-2">
                    <div className='d-flex justify-content-around'>
                        <h5>اسم الفني: {Points?.technicalName}</h5>
                        <h5>رقم الهاتف : {Points?.technicalPhoneNumber}</h5>
                    </div>

                </span>




            </div>
            {Points ?
                <table className="table table-bordered text-center ">
                    {Points?.orders?.map((order) => {
                        return <tbody className='w-100' >
                            <tr>
                                <th colSpan='3' className="text-center bg-danger bg-opacity-50 py-3">رقم الطلبية: #{order.orderId}</th>
                            </tr>
                            <tr className=' '>
                                <th className="text-center bg-danger bg-opacity-25" >اسم  المورد</th>
                                <th className="text-center bg-danger bg-opacity-25" >المدفوع</th>
                                <th className="text-center bg-danger bg-opacity-25" >عدد الكوبونات</th>

                            </tr>
                            {order?.supplierCouponResults?.map((Coupon) => {
                                return <tr>
                                    <td className='fw-bold' >{Coupon?.supplierName}</td>
                                    <td className='fw-bold' >{Coupon?.totalAmount}</td>
                                    <td className='fw-bold'  >{Coupon?.applicableCoupons}</td>
                                </tr>
                            })}

                        </tbody>
                    })}
                    <tfoot className='w-100' >
                        <tr>
                            <th colSpan='3' className="text-center bg-danger bg-opacity-50 py-3">المجموع الكلي</th>
                        </tr>
                        <tr className=' '>
                            <th className="text-center bg-danger bg-opacity-25" >اسم  المورد</th>
                            <th className="text-center bg-danger bg-opacity-25" >المدفوع</th>
                            <th className="text-center bg-danger bg-opacity-25" >عدد الكوبونات</th>

                        </tr>
                        {Points?.supplierSummaries?.map((Coupon) => {
                            return <tr>
                                <td className='fw-bold' >{Coupon?.supplierName}</td>
                                <td className='fw-bold' >{Coupon?.totalAmount}</td>
                                <td className='fw-bold'  >{Coupon?.totalCoupons}</td>
                            </tr>
                        })}

                    </tfoot>

                </table>
                :
                <Card className='text-center'>
                    <h3>قم بأختيار احد الفنيين لعرض نقاطه</h3>
                </Card>
            }


        </div>
    )
}
