import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Skeleton,
  Pagination,
  Input,
  FloatButton,
  Spin,
  Tooltip,
  Switch,
  Select,
  Avatar,
} from "antd";
import useDocumentTitle from "../../Customized Hooks/useDocumentTitle";
import Navbar from "../Navigation/Navbar/Navbar";
import ProductsActions from "../UIcomponents/ProductsActions";
import AddProductModal from "./Add Product/AddProduct";

export default function Products({ baseUrl }) {
  useDocumentTitle("Products");
  const [current, setCurrent] = useState(1);
  const [numofPages, setnumofPages] = useState();
  const [Products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editLoading, seteditLoading] = useState(false);
  const [catagories, setCatagories] = useState();
  const [suppliers, setSuppliers] = useState();
  const [flag, setflag] = useState(false);
  const [ismodalOpen, setismodalOpen] = useState(false);
  const [Stores, setStores] = useState([]);

  const [supplierCode, setSupplierCode] = useState();
  const [catogeryCode, setcatogeryCode] = useState();
  const [name, setName] = useState();

  useEffect(() => {
    getCatagories();
    getSuppliers();
    getStores()
  }, []);

  useEffect(() => {

    SearchByNameAndFilter();

  }, [current]);


  async function getStores() {
    try {
      const response = await axios.get(`${baseUrl}/api/Stores/GetAll`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
        },
      });
      console.log("response fetching Stores:", response);
      setStores(response.data.stores);
    } catch (error) {
      console.error("Error fetching Stores list:", error);
    }
  }
  async function getCatagories() {
    setLoading(true);
    await axios
      .get(`${baseUrl}/api/Categories/GetAll`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
        },
      })
      .then((response) => {
        setLoading(false);
        console.log("categories res", response);
        console.log("categories", response.data.categories);

        setCatagories(response.data.categories);
        console.log(catagories);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  async function getSuppliers() {
    setLoading(true);
    await axios
      .get(`${baseUrl}/api/Suppliers/GetAll`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
        },
      })
      .then((response) => {
        setLoading(false);
        console.log("supplier res", response);
        console.log("suppliers", response.data.suppliers);
        setSuppliers(response.data.suppliers);
        console.log(` supplier : ${suppliers}`);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function PaginationChange(e) {
    setCurrent(e);
    SearchByNameAndFilter(name, catogeryCode, supplierCode);
  }

  async function SearchByNameAndFilter(reset) {
    setLoading(true);
    let url = reset ?
      `${baseUrl}/api/Products/SearchProduct?PageNumber=${current}&PageSize=20`
      :
      `${baseUrl}/api/Products/SearchProduct?PageNumber=${current}&PageSize=20
    ${name ? `&Name=${name}` : ""}
    ${catogeryCode ? `&CategoryID=${catogeryCode}` : ""}
    ${supplierCode ? `&SupplierID=${supplierCode}` : ""}`;

    console.log("Constructed URL:", url);

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
        },
      });
      setLoading(false);

      if (response.data && response.data.products) {
        console.log("Filtered products:", response.data);
        setProducts(response.data.products);
        setnumofPages(response?.data?.totalPages);
        if (current > response?.data?.totalPages && response?.data?.totalPages !== 0) {
          setCurrent(response?.data?.totalPages)
        }
      } else {
        console.error("No products found for the given search criteria.");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  function handleNavigate() {
    localStorage.setItem("name", name ?? null);
    localStorage.setItem("catogeryCode", catogeryCode ?? null);
    localStorage.setItem("supplierCode", supplierCode ?? null);
    setismodalOpen(true)
  }

  async function handleClear() {
    setName("");
    setcatogeryCode(null);
    setSupplierCode(null);
    localStorage.removeItem("name");
    localStorage.removeItem("catogeryCode");
    localStorage.removeItem("supplierCode");
    SearchByNameAndFilter(true);
  }
  async function reSetFlags() {
    await axios
      .post(
        `${baseUrl}/api/Products/ReviewAllProductByFalse`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
          },
        }
      )
      .then((res) => {
        SearchByNameAndFilter();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getOutOfStock() {
    setLoading(true);
    await axios
      .get(`${baseUrl}/api/Products/GetProductByAmount?Amount=0`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
        },
      })
      .then((response) => {
        setLoading(false);
        console.log("supplier res", response);
        console.log("suppliers", response.data.suppliers);
        setProducts(response.data.products);
        console.log(` supplier : ${Products}`);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  return (
    <>
      <Navbar
        baseUrl={baseUrl}
        setLoading={setLoading}
        setProducts={setProducts}
        showSearch
      />
      {editLoading ? <Spin fullscreen /> : ""}
      <div className="container-fluid d-flex justify-content-center align-items-center ">
        <div className="mx-auto  container ">
          <div className="d-flex justify-content-between ms-auto my-3 gap-2 flex-wrap">
            <div className="d-flex gap-2 h-20 col" >
              <Input
                size="large"
                prefix={<i className="fa fa-search text-secondary " />}
                placeholder={"اسم منتج"}
                className=""
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
              />
              <Select
                placeholder={"المورد"}
                optionFilterProp="label" // Use "label" for filtering
                options={suppliers?.map((item) => ({
                  label: item.name,
                  value: item.id ? item.id : item.code,
                }))}
                className="custom-dropdown w-100 h-100"
                showSearch // Enable search functionality
                onChange={(e) => {
                  setSupplierCode(e);
                }} // Handle select change
                allowClear
                onClear={() => setSupplierCode("")}
                value={supplierCode}
              />
              <Select
                placeholder={"الفئة"}
                optionFilterProp="label" // Use "label" for filtering
                options={catagories?.map((item) => ({
                  label: item.name,
                  value: item.id ? item.id : item.code,
                }))}
                className="custom-dropdown w-100 h-100"
                showSearch // Enable search functionality
                onChange={(e) => {
                  setcatogeryCode(e);
                }} // Handle select change
                allowClear
                onClear={() => setcatogeryCode("")}
                value={catogeryCode}
              />
              <button
                className="btn btn-primary rounded-5   px-3 py-2"
                onClick={() => SearchByNameAndFilter()}
              >
                بحث
              </button>
            </div>
            <button
              onClick={() => {
                handleClear();
              }}
              className="btn btn-red rounded-5 mx-1 px-2 py-2"
            >
              <i className="fa fa-trash"></i> مسح البحث
            </button>

          </div>

          <div className=" overflow-x-auto">
            <div className="d-flex justify-content-between mt-4 mb-2">
              <div className="">
                <button
                  onClick={() => {
                    handleNavigate();
                  }}
                  className="btn btn-primary rounded-5 mx-1 px-2 py-2"
                >
                  <i className="fa-solid fa-plus"></i> أضف منتج
                </button>
                <button
                  onClick={() => {
                    getOutOfStock();
                  }}
                  className="btn btn-red rounded-5  mx-1 px-2 py-2"
                >
                  <i class="far fa-times-circle"></i> منتهي
                </button>

              </div>
              <Pagination
                current={current}
                onChange={PaginationChange}
                pageSize={5}
                total={numofPages ? numofPages * 5 : 0}
                showSizeChanger={false}
              />

            </div>
            <table className="table table-bordered custom-table ">
              <thead>
                <tr className="">
                  <th className="text-center">  المنتج</th>
                  <th className="text-center">المورد</th>
                  <th className="text-center"> الكميه</th>
                  <th className="text-center">سعر الشراء</th>
                  <th className="text-center"> سعر البيع</th>
                  <th className="text-center">الكود</th>
                  <th className="text-center col-1">
                    <h6>جرد</h6>
                    <Switch
                      defaultChecked
                      onChange={(e) => setflag(e)}
                      value={flag}
                    />
                  </th>
                </tr>
              </thead>
              {loading ? (
                <></>
              ) : (
                <>
                  <tbody>
                    {Products?.map((Product, index) => (
                      <tr key={index} className="">
                        <td className="">
                          <span className="d-flex gap-2 align-items-center">
                            <Avatar src={`${baseUrl}/api/Storage/GetImageById?id=${Product?.photos[0]?.photoId}`} />
                            <h6 className=" font-inter "> {Product.name}</h6>

                          </span>
                        </td>

                        <td className="text-center font-inter">
                          {Product.supplierName}
                        </td>
                        <td className="text-center font-inter">
                          {Product.amount}
                        </td>

                        <td className="text-center font-inter">
                          {Product?.buyingPrice}
                        </td>
                        <td className="text-center font-inter">
                          {Product.sellingPrice}
                        </td>

                        <td className="text-center font-inter">
                          {Product.barCode}
                        </td>
                        <td className="text-center font-inter">
                          {suppliers?.length < 0 && catagories?.length < 0 && Stores?.length < 0 ?
                            '' :
                          <ProductsActions
                            baseUrl={baseUrl}
                            flag={flag}
                            getProducts={SearchByNameAndFilter}
                            id={Product?.id}
                            seteditLoading={seteditLoading}
                            ischecked={Product?.review}
                              suppliers={suppliers}
                              catagories={catagories}
                              Stores={Stores}
                          />}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </table>
          </div>
          {loading ? (
            <>
              <div className="container-lg mt-2">
                <div className="row">
                  <div className="col-3">
                    <Skeleton
                      active
                      loading={true}
                      paragraph={{
                        rows: 3,
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <Skeleton
                      active
                      loading={true}
                      paragraph={{
                        rows: 3,
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <Skeleton
                      active
                      loading={true}
                      paragraph={{
                        rows: 3,
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <Skeleton
                      active
                      loading={true}
                      paragraph={{
                        rows: 3,
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}


        </div>
      </div>

      <FloatButton.Group type="primary">
        <FloatButton.BackTop type="primary" />
        {flag ? (
          <Tooltip title="اعادة تعيين الجرد">
            {" "}
            <FloatButton
              type="primary"
              onClick={() => reSetFlags()}
              icon={<i className="fa fa-x fa-lg" />}
            />{" "}
          </Tooltip>
        ) : (
          ""
        )}
      </FloatButton.Group>
      {suppliers?.length < 0 && catagories?.length < 0 && Stores?.length < 0  ?
      '':
        <AddProductModal baseUrl={baseUrl} ismodalOpen={ismodalOpen} setismodalOpen={setismodalOpen}  getProducts={SearchByNameAndFilter} categories={catagories} Stores={Stores} Suppliers={suppliers} />}

      
    </>
  );
}
