import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Modal, Pagination, Skeleton, notification, message, Popconfirm, Button, Input, Select } from "antd";
import useDocumentTitle from '../../Customized Hooks/useDocumentTitle';
import { Option } from 'antd/es/mentions';

export default function Users({ baseUrl }) {
    useDocumentTitle('الموظفين')
    const [password, setPassword] = useState('');
    const [current, setCurrent] = useState(1);
    const [numofPages, setnumofPages] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [Users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpenForEdit, setIsModalOpenForEdit] = useState(false);
    const [UserId, setUsersId] = useState(null);
    const [isModalOpenForAdd, setIsModalOpenForAdd] = useState(false);
    const [UserName, setUserName] = useState('');
    const [errorName, setErrorName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [sending, setsending] = useState(false);
    const [role, setrole] = useState(null);
    useEffect(() => {
        getUsers()
    }, [current]);

    async function getUsers() {
        setLoading(true)
        await axios.get(`${baseUrl}/api/User`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setLoading(false)
                console.log('users res', response);
                console.log('Users', response.data.users);
                setUsers(response.data.users);
                // setnumofPages(response.data.paginationResult.numberOfPages)
                // if (response.data.paginationResult.numberOfPages < current) {
                //     setCurrent(pre => pre - 1)
                // }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error);

            })
    }


    async function AddUser(name, password) {
        setsending(true)
        let data = {
            "username": name,
            "password": password,
            "role": role
        }

console.log(data);
        await axios.post(`${baseUrl}/api/User/CreateUser`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                console.log('add User', response);
                setsending(false)
                handleAddCancel();
                openNotificationWithIcon(
                    'success',
                    "تم بنجاج",
                    "تمت اضافة موظف بنجاح."
                );
                getUsers();
                
            })
            .catch((error) => {
                setsending(false)
                console.log(error);

            })
    }

    async function EditUser(name, phone) {
        setsending(true)

        let data = {
            "username": name,
            "phone": phone
        }
        await axios.put(`${baseUrl}phone${UserId}`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setsending(false)

                setIsModalOpenForEdit(false);
                setErrorName(null);
                console.log('edit User response : ', response);
                setUsers(response.data.UserLists);
                openNotificationWithIcon(
                    "success",
                    "تم التعديل",
                    "تم تعديل بيانات الموظف بنجاح"
                );
                getUsers()
            }).catch((error) => {
                setsending(false)

                console.log(error);

            })
    }

    const showEditModal = (id, name) => {
        console.log(id, name);
        setUsersId(id)
        setUserName(name)
        setIsModalOpenForEdit(true)
    };

    const handleEditOk = () => {
        const trimmedUserName = UserName.trim();
        const trimmedphone = password.trim();

        if (trimmedUserName === "" || trimmedUserName === null) {
            setErrorName("من فضلك ادخل اسم الموظف !");
            return;
        }

        if (trimmedphone === "" || trimmedphone === null) {
            setErrorName("من فضلك ادخل رقم الموظف !");
            return;
        }

        else if (!trimmedphone.match('^01[0-2,5]{1}[0-9]{8}$')) {
            setErrorName("تأكد من صحة الرقم");
            return;
        }

        else {
            EditUser(trimmedUserName, trimmedphone);
            setErrorName(null);

        }
    };

    const handleEditCancel = () => {
        setUserName('');
        setPassword('');
        setrole()
        setUsersId(null);
        setErrorName(null);
        setIsModalOpenForEdit(false);
    };


    const showAddModal = () => {
        setIsModalOpenForAdd(true);
    };

    function PaginationChange(e) {
        setCurrent(e)
    }

    const handleAddOk = () => {
        const trimmedUserName = UserName.trim();
        const trimmedpass = password.trim();

        if (trimmedUserName === "" || trimmedUserName === null) {
            setErrorName("من فضلك ادخل اسم الموظف !");
            return;
        }

        else if (role === "" || role === null ||role ===undefined) {
            setErrorName("من فضلك اختر الوظيفه !");
            return;
        }
        else if (trimmedpass === "" || trimmedpass === null) {
            setErrorName("من فضلك ادخل كلمة سر للموظف !");
            return;
        }
        // Check length
        else if (trimmedpass.length < 8) {
            setErrorName("يجب أن تكون كلمة المرور على الأقل 8 أحرف.");
        }

        // Check for uppercase letter
         else if (!/[A-Z]/.test(trimmedpass)) {
            setErrorName("يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل (A-Z).");
        }

        // Check for digit
        else if (!/\d/.test(trimmedpass)) {
            setErrorName("يجب أن تحتوي كلمة المرور على رقم واحد على الأقل (0-9).");
        }

        // Check for non-alphanumeric character
        else if (!/[^\w]/.test(trimmedpass)) {
            setErrorName("يجب أن تحتوي كلمة المرور على عنصر مميز مثلا (@ أو $ أو # أو _).");
        }


        // else if (!trimmedpass.match('^01[0-2,5]{1}[0-9]{8}$')) {
        //     setErrorName("تأكد من صحة الرقم");
        //     return;
        // }

        else {
            AddUser(trimmedUserName, trimmedpass);
            setErrorName(null);

        }
    };


    const handleAddCancel = () => {
        setUserName('');
        setPassword('');
        setrole()
        setErrorName(null);
        setIsModalOpenForAdd(false);
    };


    async function DeleteUser(UserId) {

        await axios.delete(`${baseUrl}/api/User?Code=${UserId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                console.log('User', response);
                openNotificationWithIcon(
                    "success",
                    "تم الحذف",
                    "تم حذف الموظف بنجاح"
                );
                getUsers()
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage(error?.response?.data?.message)
            })
    }

    function openNotificationWithIcon(type, message, description) {
        api[type]({
            message: message,
            description: description,
        });
    }

    const cancel = (e) => {
        console.log(e);
        message.error("Click on No");
    };

    return <>
        {contextHolder}
        <div className='container-fluid d-flex justify-content-center align-items-center'>


            <div className="mx-auto py-4 w-75" >
                <div className="d-flex justify-content-between ms-auto my-3">
                    <div className='w-50'>
                        <Input className='rounded-5' size='large' placeholder='ابحث عن موظف' prefix={<i className='fa fa-search text-secondary' />} />
                    </div>
                    <button onClick={showAddModal} className="btn btn-red rounded-5   px-3 py-2"><i className="fa-solid fa-plus"></i> أضف موظف</button>
                </div>
                {loading ? (
                    <>
                        <div>
                            <table className="table table-bordered custom-table">
                                <thead>
                                    <tr className=''>
                                        <th className=''> أسم الموظف</th>
                                        <th className=''> الوظيفه</th>
                                        <th className='text-center  col-1'>حذف</th>
                                    </tr>
                                </thead>
                            </table>
                            <div className="row">
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) :
                    (
                        <div className="d-flex justify-content-center align-items-center">
                            <table className="table table-bordered custom-table">
                                <thead>
                                    <tr className=''>
                                        <th className=''> أسم الموظف</th>
                                        <th className=''>الوظيفه</th>
                                        <th className='text-center  col-1'>حذف</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {Users?.map((User, index) => (
                                        <tr key={index} className=''>
                                            <td className=''>
                                                <h6>{User.name}</h6>
                                            </td>
                                            <td className=''>
                                                <h6>{User.role}</h6>
                                            </td>

                                            <td className='text-center col-1'>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="حذف هذا الموظف"
                                                    description="هل انت بالتأكيد تريد حذف هذا الموظف ؟"
                                                    onConfirm={() => DeleteUser(User.code)}
                                                    onCancel={cancel}
                                                    okText="نعم"
                                                    cancelText="لا"
                                                >
                                                    <Button type="link" className="" danger>
                                                        <i className='fa fa-trash fa-lg' />
                                                    </Button>
                                                </Popconfirm>
                                                {/* <Button type='link' onClick={() => showEditModal(User.code, User.name)}><i className='fa fa-edit fa-lg' /></Button> */}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                <div className='d-flex justify-content-end'>

                    <Pagination current={current} onChange={PaginationChange} pageSize={5} total={numofPages ? numofPages * 5 : 0} showSizeChanger={false} />
                </div>
                {errorMessage !== null && <div className='d-flex justify-content-center'>
                    <Alert className='' closable type='error' message={errorMessage}></Alert>
                </div>}
            </div>

        </div>


     



        <Modal title={<h5 className='font-inter fw-bold'>أضافة موظف</h5>} open={isModalOpenForAdd} confirmLoading={sending} onOk={handleAddOk} onCancel={handleAddCancel} okText="أضافه" cancelText='الغاء' >
            <div className='my-4'>
                <label htmlFor="UserNameInput" className='fw-bold'>أسم المستخدم</label>
                <input
                    type="text"
                    className="form-control mt-2"
                    id="UserNameInput"
                    name="UserName"
                    value={UserName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder='أدخل أسم المستخدم'
                />
                <p className='text-secondary '>يجب الا يحتوي اسم المستخدم على مسافات</p>

           <label htmlFor="role" className='fw-bold mb-2'>الوظيفه</label>
                <br />
                <Select placeholder='قم بأختيار الوظيفه' size='large' value={role} className='w-100' onChange={(e) => { setrole(e) }} >
                    <Option value={1}>موظف مخزن</Option>
                    <Option value={2}>كاشير</Option>
                </Select>
            </div>
            <label htmlFor="Pass" className='fw-bold'>كلمة السر الخاصه به</label>

            <input
                type="password"

                className="form-control mb-3 mt-2"
                id="Pass"
                name="UserName"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="أدخل كلمة السر"
            />
            <p className='text-secondary '>يجب ان تكون كلمة السر اطول من 8 احرف باللغه الانجليزيه وتحتوي على حرف كبير ورقم وعنصر مميز(مثل @ او $ او _ )</p>

            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" />
            )}
        </Modal>
    </>

} 