import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Modal, Skeleton, Pagination, notification, message, Popconfirm, Button, Avatar, Input } from "antd";
import { CloudUploadOutlined } from '@ant-design/icons'
import useDocumentTitle from '../../Customized Hooks/useDocumentTitle';

export default function Categories({ baseUrl }) {
    useDocumentTitle('فئات المنتجات')
    const [selectedImage, setSelectedImage] = useState(null);
    const [current, setCurrent] = useState(1);
    const [sending, setsending] = useState(false);
    const [numofPages, setnumofPages] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [Categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpenForEdit, setIsModalOpenForEdit] = useState(false);
    const [CategoryId, setCategoriesId] = useState(null);
    const [isModalOpenForAdd, setIsModalOpenForAdd] = useState(false);
    const [CategoryName, setCategoryName] = useState('');
    const [errorName, setErrorName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [hoverd, sethoverd] = useState(false);
    useEffect(() => {
        getCategories()
    }, [current]);

    async function getCategories() {
        setLoading(true)
        await axios.get(`${baseUrl}/api/Categories/GetAll`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setLoading(false)
                console.log('categorys res', response);
                console.log('Categories', response.data.categories);
                // if (response.data.paginationResult.numberOfPages < current) {
                //     setCurrent(pre => pre - 1)
                // }
                setCategories(response.data.categories);
                // setnumofPages(response.data.paginationResult.numberOfPages)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
            })
    }
    function PaginationChange(e) {
        setCurrent(e)
    }
  
    async function AddCategory(name) {
        setsending(true)
        let data = {
            'name': name
        }
        await axios.post(`${baseUrl}/api/Categories`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setsending(false)
                console.log('Category', response);
                setIsModalOpenForAdd(false);
                openNotificationWithIcon(
                    "success",
                    "تمت الأضافه",
                    "تم اضافة الفئه بنجاح"
                );
                getCategories();
                setCategoryName("");
                setSelectedImage(null)
            })
            .catch((error) => {
                setsending(false)
                console.log(error);
                if (error.response.status === 400) {

                    setErrorName('هذه الفئه موجوده بالفعل')
                }
            })
    }

    async function EditCategory(name) {
        setsending(true);

        let data = {
            "id": CategoryId,
            "name": name
        }
        await axios.put(`${baseUrl}/api/Categories`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setsending(false);

                setIsModalOpenForEdit(false);
                setErrorName(null);
                console.log('edit Category response : ', response);
                setCategories(response.data.CategoryLists);
                openNotificationWithIcon(
                    "success",
                    "تم التعديل",
                    "تم تعديل الفئه بنجاح"
                );
                getCategories()
            }).catch((error) => {
                console.log(error);
                setsending(false);
                if (error.response.status === 400) {

                    setErrorName('هذه الفئه موجوده بالفعل')
                }
            })
    }

    const showEditModal = (id, name) => {
        setCategoriesId(id)
        setCategoryName(name)
        setIsModalOpenForEdit(true)
    };

    const handleEditOk = () => {
        const trimmedCategoryName = CategoryName.trim();

        if (trimmedCategoryName === "" || trimmedCategoryName === null) {
            setErrorName("Please add name for the Category!");
        }
        if (trimmedCategoryName.charAt(0) === ' ') {
            setErrorName("First character should not be a space");
        }
        if (/\s\s/.test(trimmedCategoryName)) {
            setErrorName("Consecutive spaces are not allowed");
        }
        else if (trimmedCategoryName.length < 3 || trimmedCategoryName.length > 50) {
            setErrorName("Please the name of Category should be between 3 to 50 characters");
        }
        else {
            EditCategory(trimmedCategoryName);
        }
    };

    const handleEditCancel = () => {
        setCategoryName('');
        setSelectedImage(null)
        setCategoriesId(null);
        setErrorName(null);
        setIsModalOpenForEdit(false);
    };


    const showAddModal = () => {
        setIsModalOpenForAdd(true);
    };

    const handleAddOk = () => {
        const trimmedCategoryName = CategoryName.trim();

        if (trimmedCategoryName === "" || trimmedCategoryName === null) {
            setErrorName("قم يأدخال اسم الفئه!");
            return;
        }
        else {
            AddCategory(trimmedCategoryName);
            setErrorName(null);

        }
    };


    const handleAddCancel = () => {
        setCategoryName('');
        setErrorName(null);
        setIsModalOpenForAdd(false);
    };


    async function DeleteCategory(CategoryId) {

        await axios.delete(`${baseUrl}/api/Categories?id=${CategoryId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                console.log('Category', response);
                openNotificationWithIcon(
                    "success",
                    "تم الحذف",
                    "تم حذف الفئه بنجاح"
                );
                getCategories()
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage(error?.response?.data?.message)
            })
    }

    function openNotificationWithIcon(type, message, description) {
        api[type]({
            message: message,
            description: description,
        });
    }

    const cancel = (e) => {
        console.log(e);
        message.error("Click on No");
    };

    return <>
        {contextHolder}
        <div className='container-fluid d-flex justify-content-center'>


            <div className="mx-auto py-4 w-75 " >
                <div className="d-flex justify-content-between ms-auto my-3">
                    <div className='w-50'>
                        <Input className='rounded-5' size='large' placeholder='ابحث عن فئه' prefix={<i className='fa fa-search text-secondary' />} />
                    </div>
                    <button onClick={showAddModal} className="btn btn-red rounded-5   px-3 py-2"><i className="fa-solid fa-plus"></i> أضف فئه</button>
                </div>
                <div className='d-flex justify-content-center'>
                    <table className="table table-bordered custom-table">
                        <thead>
                            <tr className=''>
                                <th className=''> الفئه</th>
                                <th className='text-center  col-2'>حذف / تعديل</th>

                            </tr>
                        </thead>
                        {loading ? (
                            <></>
                        ) : (
                            <>
                                <tbody>

                                    {Categories?.map((Category, index) => (
                                        <tr key={index} className=''>
                                            <td className=''>
                                                <h6>     {Category.name}</h6>
                                            </td>
                                            <td className='text-center col-2'>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="حذف فئه"
                                                    description="هل انت متاكد من حف هذه الفئه؟"
                                                    onConfirm={() => DeleteCategory(Category.code)}
                                                    onCancel={cancel}
                                                    okText="نعم"
                                                    cancelText="لا"
                                                >
                                                    <Button type="link" danger>
                                                        <i className='fa fa-trash fa-lg' />
                                                    </Button>
                                                </Popconfirm>
                                                <Button type='link' onClick={() => showEditModal(Category.code, Category.name)}><i className='fa fa-edit fa-lg' /></Button>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </>
                        )}
                    </table>
                </div>
                {loading ? (
                    <>
                        <div className="container-lg">
                            <div className="row">
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <div className='d-flex justify-content-end'>

                    <Pagination current={current} onChange={PaginationChange} pageSize={5} total={numofPages ? numofPages * 5 : 0} showSizeChanger={false} />
                </div>
                {errorMessage !== null && <div className='d-flex justify-content-center'>
                    <Alert className='' closable type='error' message={errorMessage}></Alert>
                </div>}
            </div>

        </div>
        <Modal title={<><i className='fa fa-edit' /> Edit Category</>} open={isModalOpenForEdit} onOk={handleEditOk} onCancel={handleEditCancel} confirmLoading={sending} okText="تعديل">
            <label htmlFor="CategoryNameInputEdit" className='mt-2 fw-semibold'>Category Name</label>
            <input
                type="text"
                className="form-control mb-3"
                id="CategoryNameInputEdit"
                name="CategoryName"
                value={CategoryName}
                onChange={(e) => setCategoryName(e.target.value)}

            />

            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" />
            )}
        </Modal>




        <Modal style={{ paddingBottom: 20 }} title={<p className='font-inter fw-bold'>Add Category</p>} open={isModalOpenForAdd} onOk={handleAddOk} confirmLoading={sending} onCancel={handleAddCancel} okText="أضافه"  cancelText='الغاء'>
            <input
                type="text"
                className="form-control mb-3 mt-2"
                id="CategoryNameInput"
                name="CategoryName"
                value={CategoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                placeholder='Category Name'
            />

            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" className='mt-2' />
            )}
        </Modal>
    </>

} 