import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Alert, Modal, Input, DatePicker, Pagination, InputNumber, Skeleton, notification, message, Popconfirm, Button, Tooltip } from "antd";
import useDocumentTitle from '../../Customized Hooks/useDocumentTitle';

export default function Taxes({ baseUrl }) {
    useDocumentTitle('الضرائب')
    const [current, setCurrent] = useState(1);
    const [sending, setsending] = useState(false);
    const [TaxId, setTaxId] = useState();
    const [numofPages, setnumofPages] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [Taxes, setTaxs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpenForEdit, setIsModalOpenForEdit] = useState(false);
    const [isModalOpenForAdd, setIsModalOpenForAdd] = useState(false);
    const [TaxName, setTaxName] = useState('');
    const [errorName, setErrorName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [exDate, setexDate] = useState(null);
    const [per, setper] = useState(0);
    useEffect(() => {
        getTaxs()
    }, [current]);

    async function getTaxs() {
        setLoading(true)
        await axios.get(`${baseUrl}/api/Taxes/GetAll`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setLoading(false)
                console.log('taxes res', response);
                console.log('Taxes', response.data.data);
                setTaxs(response.data.taxes);
                setnumofPages(response.data.paginationResult.numberOfPages)
                if (response.data.paginationResult.numberOfPages < current) {
                    setCurrent(pre => pre - 1)
                }

            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
            })
    }
    function PaginationChange(e) {
        setCurrent(e)
    }

    async function AddTaxs(name) {
        setsending(true)
        let data = {

            "name": name,
            "per": per


        }

        await axios.post(`${baseUrl}/api/Taxes`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setsending(false)
                console.log('Taxes', response);
                handleAddCancel()
                openNotificationWithIcon(
                    "success",
                    "Added Successfully",
                    "Taxes added successfully."
                );
                getTaxs();
             
            })
            .catch((error) => {
                setsending(false)
                console.log(error);
                if (error.response.status === 404) {
                    setErrorName('هذه الضريبه موجوده بالفعل')
                }
            })
    }

    async function EditTaxs(name) {
        setsending(true)
        let data = {
            "id": TaxId,
            "name": name,
            "per": per
        }
        await axios.put(`${baseUrl}/api/Taxes`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setsending(false)

                handleEditCancel()
                setErrorName(null);
                console.log('edit Taxes response : ', response);
                setTaxs(response.data.TaxsLists);
                openNotificationWithIcon(
                    "success",
                    "تم التعديل",
                    "تم تعديل الضريبه بنجاح"
                );
                getTaxs()
            }).catch((error) => {
                setsending(false)

                console.log(error);
                if (error.response.status === 400) {
                    setErrorName('This tax is already exist')
                }
            })
    }



    const handleEditOk = () => {
        const trimmedTaxsName = TaxName.trim();

        if (trimmedTaxsName === "" || trimmedTaxsName === null) {
            setErrorName("Please add name for the Taxes!");
        }
        if (trimmedTaxsName.charAt(0) === ' ') {
            setErrorName("First character should not be a space");
        }
        if (/\s\s/.test(trimmedTaxsName)) {
            setErrorName("Consecutive spaces are not allowed");
        }
        else if (trimmedTaxsName.length < 2 || trimmedTaxsName.length > 50) {
            setErrorName("Please the name of Taxes should be between 2 to 50 characters");
        }
        else {
            EditTaxs(trimmedTaxsName);
        }
    };

    const handleEditCancel = () => {
        setTaxName('');
        setTaxId(null);
        setErrorName(null);
        setIsModalOpenForEdit(false);
    };


    const showAddModal = () => {
        setIsModalOpenForAdd(true);
    };

    const handleAddOk = () => {
        const trimmedTaxsName = TaxName.trim();

        if (trimmedTaxsName === "" || trimmedTaxsName === null) {
            setErrorName("Please add name for the Taxes!");
            return;
        }
        if (trimmedTaxsName.charAt(0) === ' ') {
            setErrorName("First character should not be a space");
            return;
        }
        if (/\s\s/.test(trimmedTaxsName)) {
            setErrorName("Consecutive spaces are not allowed");
            return;
        }
        else if (trimmedTaxsName.length < 2 || trimmedTaxsName.length > 50) {
            setErrorName("Please the name of Taxes should be between 2 to 50 characters");
            return;
        }
        else {
            AddTaxs(trimmedTaxsName);
            setErrorName(null);

        }
    };


    const handleAddCancel = () => {
        setTaxName('');
        setexDate(null)
        setper(0)
        setErrorName(null);
        setIsModalOpenForAdd(false);
    };


    async function DeleteTaxs(TaxsId) {

        await axios.delete(`${baseUrl}/api/Taxes?ID=${TaxsId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                console.log('Taxes', response);
                openNotificationWithIcon(
                    "success",
                    "تم الحذف",
                    "تم حذف الضريبه بنجاح"
                );
                getTaxs()
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage(error?.response?.data?.message)
            })
    }

    function openNotificationWithIcon(type, message, description) {
        api[type]({
            message: message,
            description: description,
        });
    }

    const cancel = (e) => {
        console.log(e);
        message.error("Click on No");
    };

    const showEditModal = (Tax) => {
        setTaxId(Tax.id)
        setTaxName(Tax.name)
        setper(Tax.per)
        setIsModalOpenForEdit(true)
    };
    return <>
        {contextHolder}
        <div className='container-fluid d-flex justify-content-center'>


            <div className="mx-auto py-4 w-75" >
                <div className="d-flex justify-content-between ms-auto my-3">
                    <div className='w-50'>
                        <Input className='rounded-5' size='large' placeholder='ابحث عن فني' prefix={<i className='fa fa-search text-secondary' />} />
                    </div>
                    <button onClick={showAddModal} className="btn btn-red rounded-5   px-3 py-2"><i className="fa-solid fa-plus"></i> أضف ضريبه</button>
                </div>
                <div className='d-flex justify-content-center'>
                    <table className="table table-bordered custom-table" >
                        <thead>
                            <tr className=''>
                                <th className=''> الضريبه</th>
                                <th className=''>النسبه</th>
                                <th className='text-center  col-2'>حذف / تعديل</th>
                            </tr>
                        </thead>
                        {loading ? (
                            <></>
                        ) : (
                            <>
                                <tbody>

                                    {Taxes?.map((Tax, index) => (
                                        <tr key={index} className=''>
                                            <td className=' '>
                                                <h6>{Tax.name}</h6>
                                            </td>
                                         
                                            <td className='font-inter'>{Tax?.per}%</td>
                                            <td className='text-center '>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="حذف هذه الضريبه"
                                                    description="هل انت بالتأكيد تريد حذف هذه الضريبه ؟"
                                                    onConfirm={() => DeleteTaxs(Tax.id)}
                                                    onCancel={cancel}
                                                    okText="نعم"
                                                    cancelText="لا"
                                                >
                                                    <Button type="link" danger>
                                                        <i className='fa fa-trash  fa-lg' />
                                                    </Button>
                                                </Popconfirm>
                                                <Button type='link' onClick={() => showEditModal(Tax)}><i className='fa fa-edit fa-lg' /></Button>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </>
                        )}
                    </table>
                </div>
                {loading ? (
                    <>
                        <div className="container-lg">
                            <div className="row">
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <></>
                )}

                <div className='d-flex justify-content-end'>

                    <Pagination current={current} onChange={PaginationChange} pageSize={5} total={numofPages ? numofPages * 5 : 0} showSizeChanger={false} />
                </div>
                {errorMessage !== null && <div className='d-flex justify-content-center'>
                    <Alert className='' closable type='error' message={errorMessage}></Alert>
                </div>}
            </div>

        </div>





        <Modal style={{ paddingBottom: 20 }} confirmLoading={sending} closeIcon={false} open={isModalOpenForAdd} onOk={handleAddOk} onCancel={handleAddCancel} okText="أضافه"  cancelText='الغاء'>
            <label htmlFor="code" className='font-inter fw-bold'>اسم الضريبه</label> <br />
            <Input size="large"
                id='code'
                value={TaxName}
                placeholder='اسم الضريبه'
                onChange={(e) => setTaxName(e.target.value)}
                className='mb-3'
            />
            <br />
            <label htmlFor="per" className='font-inter fw-bold'>النسبه</label> <br />
            <InputNumber
                value={per}
                id='per'
                min={0}
                size='large'
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace('%', '')}
                onChange={(e) => setper(e)}
            />

            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" className='mt-2' />
            )}
        </Modal>
        



        <Modal style={{ paddingBottom: 20 }} confirmLoading={sending} closeIcon={false} open={isModalOpenForEdit} onOk={handleEditOk} onCancel={handleEditCancel} okText="تعديل" cancelText='الغاء'>
            <label htmlFor="code" className='font-inter fw-bold'>اسم الضريبه</label> <br />
            <Input size="large"
                id='code'
                value={TaxName}
                placeholder='اسم الضريبه'
                onChange={(e) => setTaxName(e.target.value)}
                className='mb-3'
            />
            <br />
            <label htmlFor="per" className='font-inter fw-bold'>النسبه</label> <br />
            <InputNumber
                value={per}
                id='per'
                min={0}
                size='large'
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace('%', '')}
                onChange={(e) => setper(e)}
            />

            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" className='mt-2' />
            )}
        </Modal>
    </>

} 