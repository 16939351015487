import { Avatar, Button, ConfigProvider, Divider, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import axios from "axios";

export default function Navbar({
  baseUrl,
  setLoading,
  setProducts,
  showSearch
}) {
  const [width, setWidth] = useState(0);
  const [scrollFlag, setScrollFlag] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  function logout() {
    navigate("/login");
  }
  async function SearchByCode(code) {
    setLoading(true);
    await axios
      .get(`${baseUrl}/api/Products/SearchProduct?BarCode=${code}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
        },
      })
      .then((response) => {
        setLoading(false);
        console.log("products res", response);
        console.log("Products", response.data.products);
        setProducts(response.data.products);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  return (
    <>
      <nav className={`navbar top-0 w-100 bg-white border-bottom z-index-for-navbar px-3 `}>
        <div className=" d-flex pt-0 justify-content-between">
          <img
            src={require("./logo.jpg")}
            alt=""
            height={"50px"}
            className="rounded-circle"
          />
        </div>

        <div className=" d-flex justify-content-around w-50 align-items-center">
          <Link className="text-decoration-none text-black fw-bold" to='/casher/statement'>كشف الحساب</Link>
            <Divider type="vertical" className="bg-red" />
          
          <Link className="text-decoration-none text-black fw-bold" to='/casher/points'>أدارة النقاط</Link>
          <Divider type="vertical"  className="bg-red"/>

          <Link className="text-decoration-none text-black fw-bold" to='/casher'>الصفحه الرئيسيه</Link>
          <Divider type="vertical"  className="bg-red"/>

          <Link className="text-decoration-none text-black fw-bold" to='/casher/last-orders'>البحث عن طلب</Link>
          <Divider type="vertical" className="bg-red" />
          
          <Link className="text-decoration-none text-black fw-bold" to='/casher/products-info'>الاستعلام عن منتج</Link>
          
        </div>

        <div>
          <button
            type="primary"
            danger
            className="rounded-5 btn btn-red"
            onClick={logout}
          >
            {" "}
            تسجيل الخروج <i className="fa fa-sign-out"></i>
          </button>
        </div>
      </nav>
    </>
  );
}
