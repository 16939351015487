import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

export default function Blank({baseUrl}) {
    const navigate = useNavigate();
    const {id} =useParams()
    async function printOrder() {
        await axios
            .get(`${baseUrl}/api/Orders/GetOrderDetails?OrderID=${id}`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("casherToken"),
                },
            })
            .then((response) => {
                console.log("Order", response.data.orders);
                navigate("/print-order", { state: { Order: response.data.orders } });

            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        
        printOrder()
    }, []);
  return (
    <div>
      
    </div>
  )
}
