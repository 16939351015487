import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Alert,
    Skeleton,
    Input,
    Button,
    Tooltip,
} from "antd";
import useDocumentTitle from "../../Customized Hooks/useDocumentTitle";
import Navbar from "../Navigation/Navbar/Navbar";

export default function Needs({ baseUrl }) {
    useDocumentTitle("needs");
    const [Products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [wait, setwait] = useState();
    const [name, setName] = useState();

    useEffect(() => {
        getNeeds()
    }, []);


    async function getNeeds() {
        setLoading(true);
        await axios
            .get(`${baseUrl}/api/MissingProduct/GetMissingProduct`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
                },
            })
            .then((response) => {
                setLoading(false);
                console.log("products res", response);
                console.log("Products", response.data.missingProducts);

                let sortedProducts = response.data.missingProducts.sort((a, b) =>
                    a.productName.localeCompare(b.productName, "ar")
                );

                setProducts(sortedProducts);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    async function addNeed() {
        if (name === undefined) {
            return
        }
        setwait(true);
        let data = {
            "productName": name
        }
        await axios.post(`${baseUrl}/api/MissingProduct/AddMissingProduct`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
            },
        })
            .then((response) => {
                setwait(false);
                setName()
                getNeeds()
            })
            .catch((error) => {
                setwait(false);
                console.log(error);
            });
    }
    async function arrived(id, current) {

        let data = {
            "id": id,
            "arrived": !current
        }
        await axios.post(`${baseUrl}/api/MissingProduct/MakeItArrived`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
            },
        })
            .then((response) => {
                console.log(response);
                getNeeds()
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <>
            <Navbar
                baseUrl={baseUrl}
                setLoading={setLoading}
                setProducts={setProducts}
            />
            <div className="container-fluid d-flex justify-content-center align-items-center">
                <div className="mx-auto  w-75 ">
                    <div className="d-flex justify-content-between ms-auto my-3 gap-2">
                        <div className="d-flex gap-2 h-20 w-100" >
                            <Input
                                size="large"
                                prefix={<i className="fa fa-tag text-secondary ms-2 my-auto" />}
                                placeholder={"اسم منتج"}
                                className="w-25"
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                                value={name}
                            />


                            <button
                                className={`btn ${wait ? 'btn-secondary' : 'btn-red'} rounded-5   px-3 py-2`}
                                onClick={() => { addNeed() }}
                                disabled={wait}
                            >
                                أضافه
                            </button>
                        </div>

                    </div>

                    <div className=" overflow-x-auto">
                        <table className="table table-bordered custom-table ">
                            <thead>
                                <tr className="">
                                    <th className="text-center"> أسم المنتج</th>
                                    {/* <th className="text-center">المورد</th>
                                    <th className="text-center"> الكميه</th>
                                    <th className="text-center">الكود</th> */}
                                    <th className="text-center col-1">

                                    </th>
                                </tr>
                            </thead>
                            {loading ? (
                                <></>
                            ) : (
                                <>
                                    <tbody>
                                        {Products?.map((Product, index) => (
                                            <tr key={index} className="">
                                                <td className="">
                                                    <h6 className=" font-inter "> {Product.productName}</h6>
                                                </td>

                                                {/* <td className="text-center font-inter">
                                                    {Product.supplierName}
                                                </td>
                                                <td className="text-center font-inter">
                                                    {Product.amount}
                                                </td>
                                              
                                                <td className="text-center font-inter">
                                                    {Product.barCode}
                                                </td> */}
                                                <td className="text-center font-inter">
                                                    <Button type="link"  size="large" onClick={() => { arrived(Product.id, Product?.arrived) }}>
                                                        {Product?.arrived ? <Tooltip title='تم توفيره'> <i class="fa-solid fa-check-circle fa-lg text-success"></i> </Tooltip> : <Tooltip title='غير متوفر بعد'> <i class="fa-solid fa-clipboard-check fa-lg "></i> </Tooltip>}
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </>
                            )}
                        </table>
                    </div>
                    {loading ? (
                        <>
                            <div className="container-lg mt-2">
                                <div className="row">
                                    <div className="col-3">
                                        <Skeleton
                                            active
                                            loading={true}
                                            paragraph={{
                                                rows: 3,
                                            }}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <Skeleton
                                            active
                                            loading={true}
                                            paragraph={{
                                                rows: 3,
                                            }}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <Skeleton
                                            active
                                            loading={true}
                                            paragraph={{
                                                rows: 3,
                                            }}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <Skeleton
                                            active
                                            loading={true}
                                            paragraph={{
                                                rows: 3,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    {errorMessage !== null && (
                        <div className="d-flex justify-content-center">
                            <Alert
                                className=""
                                closable
                                type="error"
                                message={errorMessage}
                            ></Alert>
                        </div>
                    )}
                </div>
            </div>

        </>
    );
}

