import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Alert, Modal, Input, DatePicker, Pagination, InputNumber, Skeleton, notification, message, Popconfirm, Button, Tooltip } from "antd";
import useDocumentTitle from '../../Customized Hooks/useDocumentTitle';

export default function Stores({ baseUrl }) {
    useDocumentTitle('المخازن')
    const [current, setCurrent] = useState(1);
    const [sending, setsending] = useState(false);
    const [StoreId, setStoreId] = useState();
    const [numofPages, setnumofPages] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [Stores, setStores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpenForEdit, setIsModalOpenForEdit] = useState(false);
    const [isModalOpenForAdd, setIsModalOpenForAdd] = useState(false);
    const [StoreName, setStoreName] = useState('');
    const [errorName, setErrorName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [exDate, setexDate] = useState(null);
    const [dis, setdis] = useState(0);
    useEffect(() => {
        getStores()
    }, [current]);

    async function getStores() {
        setLoading(true)
        await axios.get(`${baseUrl}/api/Stores/GetAll`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setLoading(false)
                console.log('stores res', response);
                console.log('Stores', response.data.stores);
                setStores(response.data.stores);
                // setnumofPages(response.data.paginationResult.numberOfPages)
                // if (response.data.paginationResult.numberOfPages<current) {
                //     setCurrent(pre=>pre-1)
                // }

            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
            })
    }
    function PaginationChange(e) {
        setCurrent(e)
    }
    async function AddStores(name) {
        setsending(true)
        let data = {
            "name": name
        }

        await axios.post(`${baseUrl}/api/Stores`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setsending(false)
                console.log('Stores', response);
                setIsModalOpenForAdd(false);
                openNotificationWithIcon(
                    "success",
                    "Added Successfully",
                    "Stores added successfully."
                );
                getStores();
                setStoreName("");
            })
            .catch((error) => {
                setsending(false)
                console.log(error);
                if (error.response.status === 400) {
                    setErrorName('This store is already exist')
                }
            })
    }

    async function EditStores(name) {
        setsending(true)
        let data = {
            "code": StoreId,
            "name": name
        }
        await axios.put(`${baseUrl}/api/Stores`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setsending(false)

                setIsModalOpenForEdit(false);
                setErrorName(null);
                console.log('edit Stores response : ', response);
                setStores(response.data.StoresLists);
                openNotificationWithIcon(
                    "success",
                    "تم التعديل",
                    "تم تعديل المخزن بنجاح"
                );
                getStores()
            }).catch((error) => {
                setsending(false)

                console.log(error);
                if (error.response.status === 400) {
                    setErrorName('This store is already exist')
                }
            })
    }



    const handleEditOk = () => {
        const trimmedStoresName = StoreName.trim();

        if (trimmedStoresName === "" || trimmedStoresName === null) {
            setErrorName("Please add name for the Stores!");
        }
        if (trimmedStoresName.charAt(0) === ' ') {
            setErrorName("First character should not be a space");
        }
        if (/\s\s/.test(trimmedStoresName)) {
            setErrorName("Consecutive spaces are not allowed");
        }
        else if (trimmedStoresName.length < 2 || trimmedStoresName.length > 50) {
            setErrorName("Please the name of Stores should be between 2 to 50 characters");
        }
        else {
            EditStores(trimmedStoresName);
        }
    };

    const handleEditCancel = () => {
        setStoreName('');
        setStoreId(null);
        setErrorName(null);
        setIsModalOpenForEdit(false);
    };


    const showAddModal = () => {
        setIsModalOpenForAdd(true);
    };

    const handleAddOk = () => {
        const trimmedStoresName = StoreName.trim();

        if (trimmedStoresName === "" || trimmedStoresName === null) {
            setErrorName("Please add name for the Stores!");
            return;
        }
        if (trimmedStoresName.charAt(0) === ' ') {
            setErrorName("First character should not be a space");
            return;
        }
        if (/\s\s/.test(trimmedStoresName)) {
            setErrorName("Consecutive spaces are not allowed");
            return;
        }
        else if (trimmedStoresName.length < 2 || trimmedStoresName.length > 50) {
            setErrorName("Please the name of Stores should be between 2 to 50 characters");
            return;
        }
        else {
            AddStores(trimmedStoresName);
            setErrorName(null);

        }
    };


    const handleAddCancel = () => {
        setStoreName('');
        setexDate(null)
        setdis(0)
        setErrorName(null);
        setIsModalOpenForAdd(false);
    };


    async function DeleteStores(StoresId) {

        await axios.delete(`${baseUrl}/api/Stores?Code=${StoresId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                console.log('Stores', response);
                openNotificationWithIcon(
                    "success",
                    "تم حذف المخزن"
                );
                getStores()
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage(error?.response?.data?.message)
            })
    }

    function openNotificationWithIcon(type, message, description) {
        api[type]({
            message: message,
            description: description,
        });
    }

    const cancel = (e) => {
        console.log(e);
        message.error("Click on No");
    };
    const showEditModal = (id, name) => {
        console.log(id, name);
        setStoreId(id)
        setStoreName(name)
        setIsModalOpenForEdit(true)
    };
    return <>
        {contextHolder}
        <div className='container-fluid d-flex justify-content-center'>


            <div className="mx-auto py-4 w-75" >
                <div className="d-flex justify-content-between ms-auto my-3">
                    <div className='w-50'>
                        <Input className='rounded-5' size='large' placeholder='ابحث عن مخزن' prefix={<i className='fa fa-search text-secondary' />} />
                    </div>
                    <button onClick={showAddModal} className="btn btn-red rounded-5   px-3 py-2"><i className="fa-solid fa-plus"></i> أضف مخزن</button>
                </div>
                <div className='d-flex justify-content-center'>
                    <table className="table table-bordered custom-table" >
                        <thead>
                            <tr className=''>
                                <th className=''> أسم المخزن</th>

                                <th className='col-2'>حذف / تعديل</th>
                            </tr>
                        </thead>
                        {loading ? (
                            <></>
                        ) : (
                            <>
                                <tbody>

                                    {Stores?.map((Store, index) => (
                                        <tr key={index} className=''>
                                            <td className=' '>
                                                <div className='align-items-center d-md-flex d-none'>
                                                    <h6>{Store.name}</h6>
                                                </div>
                                            </td>

                                            <td className='text-center col-2'>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="حذف مخذن"
                                                    description="هل انت متأكد من حذف المخزن"
                                                    onConfirm={() => DeleteStores(Store.code)}
                                                    onCancel={cancel}
                                                    okText="نعم"
                                                    cancelText="لا"
                                                >
                                                    <Button type="link" danger>
                                                        <i className='fa fa-trash  fa-lg' />
                                                    </Button>
                                                </Popconfirm>
                                                <Button type='link' onClick={() => showEditModal(Store.code, Store.name)}><i className='fa fa-edit fa-lg' /></Button>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </>
                        )}
                    </table>
                </div>
                {loading ? (
                    <>
                        <div className="container-lg">
                            <div className="row">
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <></>
                )}

                <div className='d-flex justify-content-end'>

                    <Pagination current={current} onChange={PaginationChange} pageSize={5} total={numofPages ? numofPages * 5 : 0} showSizeChanger={false} />
                </div>
                {errorMessage !== null && <div className='d-flex justify-content-center'>
                    <Alert className='' closable type='error' message={errorMessage}></Alert>
                </div>}
            </div>

        </div>





        <Modal style={{ paddingBottom: 20 }} confirmLoading={sending} closeIcon={false} open={isModalOpenForAdd} onOk={handleAddOk} onCancel={handleAddCancel} okText="أضافه"  cancelText='الغاء'>
            <label htmlFor="code" className='font-inter fw-semibold'>أسم المخزن</label> <br />
            <Input size="large"
                id='code'
                value={StoreName}
                placeholder='أدخل اسم المخزن'
                onChange={(e) => setStoreName(e.target.value)}
                className='mb-3'
            />

            <br />


            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" className='mt-2' />
            )}
        </Modal>
        <Modal title={<><i className='fa fa-edit' /> Edit Brand</>} open={isModalOpenForEdit} onOk={handleEditOk} confirmLoading={sending} onCancel={handleEditCancel} okText="تعديل">
            <label htmlFor="BrandNameInputEdit" className='mt-2 fw-semibold'>Brand Name</label>
            <input
                type="text"
                className="form-control mb-3"
                id="BrandNameInputEdit"
                name="BrandName"
                value={StoreName}
                onChange={(e) => setStoreName(e.target.value)}

            />

            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" />
            )}
        </Modal>
    </>

} 