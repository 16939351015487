import { Alert, Button, Checkbox, DatePicker, Modal, Select, Spin } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function Statement({ baseUrl }) {
    const [technicians, settechnicians] = useState();
    const [customerID, setcustomerID] = useState();
    const [technicianID, settechnicianID] = useState();
    const [Customers, setCustomers] = useState();
    const [Loading, setLoading] = useState();
    const [statement, setstatement] = useState();
    const [orderID, setorderID] = useState();
    const [payVisible, setpayVisible] = useState();
    const [Money, setMoney] = useState(null);
    const [errorName, seterrorName] = useState(null);
    const [checked, setChecked] = useState(false);
    const [from, setfrom] = useState();
    const [remain, setremain] = useState();
    const [to, setto] = useState();
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    async function getTechnicians() {
        await axios.get(`${baseUrl}/api/Technicians/GetAll`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('Technicians res', response);
                settechnicians(response.data.technicians)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error);

            })
    }
    async function getCustomers() {
        await axios.get(`${baseUrl}/api/Customers/GetAll`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('Technicians res', response);
                setCustomers(response.data.customers)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error);

            })
    }

    const onChange = (e) => {
        console.log('checked = ', e.target.checked);
        setChecked(e.target.checked);
    };

    useEffect(() => {
        getTechnicians();
        getCustomers();
    }, []);

    useEffect(() => {
        getStatment()
    }, [customerID, technicianID, from, to ,checked]);

    async function getStatment() {
        setLoading(true)
        await axios.get(`${baseUrl}/api/Orders/GetAcountStatement?${customerID ? `&CustomerId=${customerID}` : ''}${technicianID ? `&TechnicalId=${technicianID}` : ''}&DateFrom=${from ? `${from}` : checked ? '' :'2024-8-26'}${to ? `&DateTo=${to}` : ''}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('Statments res', response);
                setstatement(response.data)
                setLoading(false)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error);

            })
    }

    async function printOrder(id) {
        window.open(`/casher/blank/${id}`, '_blank');
    }

    function handlePayOpen(id, remain) {
        setorderID(id)
        setpayVisible(true)
        setremain(remain)

    }

    function handlePayCancle(id) {
        setorderID()
        setpayVisible(false)
        setMoney(null)
        seterrorName(null)
        setremain(null)
    }

    async function pay() {
        if (Money === null) {
            seterrorName('قم بأدخال النقديه')
            return
        }
        let data = {
            "orderID": orderID,
            "paid": Money
        }
        await axios.post(`${baseUrl}/api/Orders/PayOrder`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("casherToken"),
            },
        })
            .then((response) => {
                handlePayCancle()
                getStatment()
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 400) {
                    seterrorName('المبلغ الذي تم ادخاله اكثر من المستحق')
                }
            });
    }

    useEffect(() => {

        console.log(to);
        console.log(from);
    }, [to, from]);

    return (
        <div className='container'>
            {Loading ? <Spin fullscreen /> : ''}
            <div className="row align-items-center ms-auto  w-100 p-3 mt-4">
                <span className="col-3">
                    <label htmlFor="" className='fw-bold ms-2 '>أسم الفني:</label><br />
                    <Select optionFilterProp="children"
                        filterOption={filterOption}
                        showSearch options={technicians?.map((item) => { return { label: item.name, value: item.code } })}
                        placeholder='أسم الفني' className='w-75' onChange={(e) => { settechnicianID(e) }}
                        size="large"
                        allowClear
                    />
                </span>

                <span className="col-3">
                    <label htmlFor="" className='fw-bold ms-2 '>أسم العميل:</label><br />
                    <Select optionFilterProp="children"
                        filterOption={filterOption}
                        showSearch options={Customers?.map((item) => { return { label: item.name, value: item.code } })}
                        placeholder='أسم العميل' className='w-75' onChange={(e) => { setcustomerID(e) }}
                        size="large"
                        allowClear
                    />
                </span>
                <span className="col-3">
                    <label htmlFor="" className='fw-bold ms-2 '>من:</label><br />
                    <DatePicker
                        placeholder={'اختر التاريخ'}
                        className='w-75'
                        onChange={(e) => { setfrom(e ? `${e?.$y}-${e?.$M + 1}-${e?.$D}` : e) }}
                        size="large"
                        allowClear

                    />
                </span>
                <span className="col-3">
                    <label htmlFor="" className='fw-bold '>الى:</label><br />
                    <DatePicker
                        placeholder={'اختر التاريخ'}
                        className='w-75'
                        onChange={(e) => { setto(e ? `${e?.$y}-${e?.$M + 1}-${e?.$D}` : e) }}
                        size="large"
                        allowClear
                    />
                </span>

            </div>
            <div className='container my-2'>
                <Checkbox checked={checked} onChange={onChange} >
                    عرض ما الفواتير القديمه
                </Checkbox>
            </div>
            <table className="table table-bordered custom-table">
                <thead>
                    <tr>
                        <th className="text-center">رقم الطلبية</th>
                        <th className="text-center">اسم العميل</th>
                        <th className="text-center">اسم الفني</th>
                        {/* <th className="text-center">التاريخ</th> */}
                        <th className="text-center">المبلغ المدفوع</th>
                        <th className="text-center">المتبقي</th>
                        <th className="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    {statement?.orders.map((e) => {
                        return (
                            <tr>
                                <td>{e.orderId}</td>
                                <td>{e.customerName}</td>
                                <td>{e.technicalName}</td>
                                {/* <td>{e.date}</td> */}
                                <td>{e.paid}</td>
                                <td>{e.remain}</td>
                                <td className="p-3">
                                    {e.remain === 0 ?
                                        <i className="fa-regular fa-check-circle fa-lg text-success mx-3"></i>

                                        :
                                        <Button type='link' onClick={() => handlePayOpen(e.orderId, e.remain)}>
                                            <i className="fa-solid fa-money-bills fa-lg text-success"></i>
                                        </Button>
                                    }
                                    <Button type='link' onClick={() => printOrder(e.orderId)}>
                                        <i className="fa fa-print fa-lg " />
                                    </Button>

                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td className='fw-bold'>اجمالي المدفوع</td>
                        <td className='fw-bold' colSpan={5}>{statement?.totalPaid}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold'>اجمالي المتبقي</td>
                        <td className='fw-bold' colSpan={5}>{statement?.totalRemain}</td>
                    </tr>
                </tfoot>
            </table>

            <Modal
                visible={payVisible}
                onOk={pay}
                onCancel={handlePayCancle}
                okText='تأكيد'
                cancelText='الغاء'
                closeIcon={false}
                className=""
            >
                <h6 className='text-center'>المبلغ المتبقي: {remain}</h6>
                <label htmlFor="CategoryNameInput" className='fw-bold'>ادخل النقد الذي تم استلامه  :</label>
                <input
                    type="number"
                    min={0}
                    accept=''
                    inputMode='numeric'
                    className="form-control mb-3 mt-2"
                    id="CategoryNameInput"
                    name="CategoryName"
                    value={Money}
                    onChange={(e) => setMoney(e.target.value)}
                    placeholder='قم بأدخال النقد'
                    oninput="validity.valid||(value='');"
                />
                {errorName === null || errorName === "" ? (
                    <></>
                ) : (
                    <Alert message={errorName} type="error" className='mt-2' />
                )}
            </Modal>
        </div>
    )
}
