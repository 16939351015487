import { useEffect } from 'react'

export default function useDocumentTitle(title) {

  useEffect(() => {
    if (title === "undefined's profile")
      document.title = `view profile`
    else
      document.title = `${title}`
  }, [title])

}
