import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import './PrintOrder.css' // Import your custom CSS

export default function PrintOrder() {
    let { Order } = useLocation().state ?? { Order: null }
    const [once, setonce] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (!once) {
                window.print()
                setonce(true)
            }
        }, 1000)
    }, [once]);


    return (
        <div className='container '>
            <div className='row gap-2'>
                <div className="d-flex align-items-center w-100 py-3">
                    <div className='col d-flex justify-content-center align-items-center flex-column'>
                        <h3 className="fw-bold text-center">إسكندر جروب</h3>
                <h6>فرع شارع الجامعة الروسيه بجوار فودافون</h6>
                    </div>
                    <img src={require('./logo.png')} alt="" className='text-end ' style={{ width: '120px' }} />
                </div>
                {/* <h6>التاريخ: {new Date().toJSON().slice(0, 10).replace(/-/g, '/')}</h6> */}
                <h6>التاريخ: {Order?.date}</h6>
                <h6>رقم الفاتوره {Order?.orderID}
                {Order?.existOrder ? 
                    Order.isReturn ? <> مرتجع من الفاتوره {Order?.existOrder}</> : <>صادر عن  الفاتوره {Order?.existOrder}</> 
                : 
                ''
                }
                </h6>
                {Order.technincalName === "none" ? null : <h6>الفني: {Order.technincalName}</h6>}
                {Order.customerName === "none" ? null : <h6>العميل: {Order.customerName}</h6>}
                <h6>تفاصيل الفاتوره: ({Order.customerDescription})</h6>
                <table class=" table-bordered border-black border-5">
                    <thead>
                        <tr>
                            <th scope="col" className='col-1  text-center'>#</th>
                            <th scope="col" className='col text-center'>الصنف</th>
                            <th scope="col" className='col-1  text-center'>الكميه</th>
                            <th scope="col" className='col-1  text-center'>السعر</th>
                            <th scope="col" className='col-2  text-center'>السعر بعد الخصم</th>
                            <th scope="col" className='col-2  text-center'> الأجمالي</th>
                            <th scope="col" className='col-2  text-center'>الأجمالي بعد الخصم</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Order?.items?.map((item, index) => {
                                return <tr>
                                    <th scope="row" className='text-center'>{index + 1}</th>
                                    <td className=''>{item.name}</td>
                                    <td className='text-center'>{item.amount}</td>
                                    <td className='text-center'>{item.price}</td>
                                    <td className='text-center'>{item?.newPrice}</td>
                                    <td className='text-center'>{item.totalbeforDis}</td>
                                    <td className='text-center'>{item.total}</td>

                                </tr>
                            })
                        }
                        <tr>
                            <th scope="row" className='text-center'>--</th>
                            <td className='fw-bold'>الإجمالي قبل الخصم</td>

                            <td className='text-center fw-bold' colSpan={5}>{Order.totalBeforeDiscount}</td>

                        </tr>
                        <tr>
                            <th scope="row" className='text-center'>--</th>
                            <td className='fw-bold'> الإجمالي بعد الخصم  </td>

                            {/* ({Order.discountPer}%) */}
                            <td className='text-center fw-bold' colSpan={5}>{Order.totalAfterDiscount}</td>

                        </tr>


                    </tbody>
                </table>
                    <ul>
                        <li>لا يتم ارجاع البضاعه بعد مروم 15 يوم من تاريخ الطلب</li>
                        <li>لا يتم ارجاع البضاعه إلا بأصل الفاتوره</li>
                        <li>لا يتم ارجاع البضاعه التالفه او بدون عبوه او مواسير الصرف في حالة القص</li>
                    </ul>
            </div>
        </div>
    )
}
