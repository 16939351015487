import React, { useEffect, useState } from "react";
import { Button, Spin, Tooltip, Modal, Divider } from "antd";
import axios from "axios";
import { CheckCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;
const baseUrl = "http://62.72.16.64:8041";

export default function OnlineOrders() {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [payModal, setPayModal] = useState(false);
    const [retriveModal, setRetriveModal] = useState(false);

    useEffect(() => {
        getOrders();
        console.log(orders);
    }, []);

    async function getOrders() {
        setOrders([]);
        setLoading(true);
        try {
            const response = await axios.get(
                `${baseUrl}/api/Cart/GetAllCarts`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("casherToken"),
                    },
                }
            );
            setOrders(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    function showConfirm(cartId) {
        confirm({
            title: "سيتم تأكيد وصول الطلب ",
            icon: <CheckCircleOutlined className="text-success" />,
            okText: "تاكيد",
            content: "تأكد من استلام المبلغ كامل",
            cancelText: "الغاء",
            onOk() {
                markAsDelivered(cartId);
            },
            onCancel() {
                console.log("Cancelled");
            },
        });
    }

    async function markAsDelivered(cartId) {
        setLoading(true);
        try {
            await axios.post(
                `${baseUrl}/api/Cart/DeliverdOrder`,
                {
                    cartId,
                    isDeliverd: true,
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("casherToken"),
                    },
                }
            );
            getOrders(); // Refresh the orders after updating the delivery status
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    function openInfoModal(order) {
        setSelectedOrder(order);
        setInfoModal(true);
    }

    function openPayModal(order) {
        console.log(order);
        setSelectedOrder(order);
        setPayModal(true); // Ensure the pay modal opens
    }
    function openRetriveModal(order) {
        setSelectedOrder(order);
        setRetriveModal(true);
    }
    return (
        <>
            {loading ? <Spin fullscreen /> : ""}
            <div className="overflow-x-auto mt-2 container">
                <table className="table table-bordered custom-table">
                    <thead>
                        <tr>
                            <th className="text-center">الحالة</th>
                            <th className="text-center">رقم الطلبية</th>
                            <th className="text-center">اسم العميل</th>
                            <th className="text-center">سعر الفاتورة</th>
                            <th className="text-center">المدفوع</th>
                            <th className="text-center">المتبقي</th>
                            <th className="text-center">نوع الطلبيه</th>
                            <th className="text-center">التاريخ</th>
                            <th className="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders?.map((order) => (
                            <tr key={order.cartId}>
                                <td
                                    className={`fw-bold ${
                                        order?.cancled === 0
                                            ? "text-danger"
                                            : order?.isDeliverd
                                            ? "text-success"
                                            : "text-primary"
                                    }`}
                                >
                                    {order?.cancled === 0
                                        ? "ملغي"
                                        : order?.isDeliverd
                                        ? "تم التسليم"
                                        : "لم يتم التسليم"}
                                </td>
                                <td>{order.cartId}</td>
                                <td>{order.userName ?? "--"}</td>
                                <td>{order.totalPrice}</td>
                                <td>{order.paid}</td>
                                <td>
                                    {order.remain === 0
                                        ? "تم الدفع"
                                        : order.remain}
                                </td>
                                <td>
                                    {order.isOnline ? (
                                        <span>
                                            <i className="fa fa-truck text-primary ">
                                                {" "}
                                                توصيل{" "}
                                            </i>
                                        </span>
                                    ) : (
                                        <span>
                                            <i className="fa-solid fa-store text-secondary">
                                                {" "}
                                                من المتجر{" "}
                                            </i>
                                        </span>
                                    )}
                                </td>
                                <td>{order.completedAt.slice(0, 10)}</td>
                                <td className="d-flex align-items-center gap-3 w-100 justify-content-start">
                                    <Tooltip title="تفاصيل الطلبيه">
                                        <Button
                                            type="link"
                                            shape="circle"
                                            onClick={() => openInfoModal(order)}
                                        >
                                            <i className="fa fa-info-circle fa-lg"></i>
                                        </Button>
                                    </Tooltip>

                                    {order.isDeliverd ? (
                                        <Tooltip title="تم التسليم">
                                            <Button
                                                type="link"
                                                shape="circle"
                                                onClick={() =>
                                                    openRetriveModal(order)
                                                }
                                            >
                                                <i className="fa-solid fa-truck-ramp-box fa-lg text-success"></i>
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="لم تصل بعد">
                                            <Button
                                                type="link"
                                                shape="circle"
                                                onClick={() =>
                                                    openRetriveModal(order)
                                                }
                                            >
                                                <i className="fa-solid fa-truck-ramp-box fa-lg"></i>
                                            </Button>
                                        </Tooltip>
                                    )}

                                    {order?.remain > 0 ? (
                                        <Tooltip title="الدفع">
                                            <Button
                                                type="link"
                                                shape="circle"
                                                onClick={() =>
                                                    openPayModal(order)
                                                }
                                            >
                                                <i className="fa-solid fa-money-bill fa-lg"></i>
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="تم الدفع">
                                            <div>
                                                <i class="fa-solid fa-check fa-lg text-success"></i>
                                            </div>
                                        </Tooltip>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal for showing order details */}
            <InfoModal
                order={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                setInfoModal={setInfoModal}
                infoModal={infoModal}
            />
            <PayModal
                order={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                setPayModal={setPayModal}
                payModal={payModal}
                getOrders={getOrders}
            />
            <RetriveModal
                order={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                setRetriveModal={setRetriveModal}
                retriveModal={retriveModal}
                getOrders={getOrders}
            />
        </>
    );
}

const InfoModal = ({ order, setInfoModal, infoModal, setSelectedOrder }) => (
    <Modal
        footer={false}
        open={infoModal}
        onCancel={() => {
            setInfoModal(false);
            setSelectedOrder(null);
        }}
        title={
            <div className="d-flex justify-content-start align-items-center gap-5">
                <div className="fs-6">تفاصيل الطلبية رقم : {order?.cartId}</div>
                <div className="fs-6">الاجمالي : {order?.totalPrice}</div>
            </div>
        }
        centered
        width={700}
    >
        <div className="container">
            <UserInfo
                username={order?.userName}
                phoneNumber={order?.phoneNumber}
                location={order?.address}
            />
            <div className="row">
                {order?.products?.map((product) => (
                    <div className="col-12 mb-3" key={product.productId}>
                        <div className="d-flex justify-content-between align-items-center border p-3 shadow-sm rounded">
                            <span className="fw-bold" style={{ width: "60%" }}>
                                {product?.productName}
                            </span>
                            <span className="fw-bold">
                                الكمية : {product?.quantity}
                            </span>
                            <span className="fw-bold">
                                الاجمالي : {product?.itemPrice}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </Modal>
);

const PayModal = ({
    order,
    setSelectedOrder,
    payModal,
    setPayModal,
    getOrders,
}) => {
    const [paymentAmount, setPaymentAmount] = useState("");

    const payFunction = async () => {
        try {
            const res = await axios.post(
                `${baseUrl}/api/Cart/PayCartOrder`,
                {
                    cartId: order?.cartId,
                    paymentAmount: paymentAmount,
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("casherToken"),
                    },
                }
            );
            getOrders();
            console.log(res);
            setPayModal(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal
            footer={false}
            open={payModal}
            onCancel={() => {
                setPayModal(false);
                setSelectedOrder(null);
            }}
            centered
            title="دفع"
        >
            <div className="container">
                {/* Order Details */}
                <div className="row mb-4">
                    <div className="col-12">
                        <h5 className="text-center mb-3 fs-6">
                            تفاصيل الطلبية
                        </h5>
                        <div className="border p-3 rounded shadow-sm">
                            <div className="row">
                                <div className="col-6">
                                    <span className="fw-bold">
                                        رقم الطلبية:
                                    </span>
                                </div>
                                <div className="col-6 text-end">
                                    <span>{order?.cartId ?? "--"}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6">
                                    <span className="fw-bold">الإجمالي:</span>
                                </div>
                                <div className="col-6 text-end">
                                    <span>
                                        {order?.totalPrice ?? "0.00"} جنيه
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6">
                                    <span className="fw-bold">المتبقي:</span>
                                </div>
                                <div className="col-6 text-end">
                                    <span>{order?.remain ?? "0.00"} جنيه</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Payment Input */}
                <div className="row mb-4">
                    <div className="col-12">
                        <h1
                            htmlFor="payment-amount"
                            className="text-center mb-3 fs-6"
                        >
                            أدخل مبلغ الدفع
                        </h1>
                        <input
                            type="number"
                            className="form-control"
                            id="payment-amount"
                            placeholder="أدخل المبلغ"
                            min="0"
                            onChange={(e) => setPaymentAmount(e.target.value)} // Set payment amount state
                        />
                    </div>
                </div>

                {/* Payment Button */}
                <div className="row">
                    <div className="col-12 text-center">
                        <Button
                            type="primary"
                            onClick={() => payFunction(order.id, paymentAmount)} // Trigger makePay
                        >
                            دفع الآن
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const RetriveModal = ({
    order,
    setRetriveModal,
    retriveModal,
    setSelectedOrder,
    getOrders
}) => {
    const editRetrive = () => {
        console.log(order);
    };

    return (
        <Modal
            open={retriveModal}
            onCancel={() => {
                setSelectedOrder(false);
                setRetriveModal(false);
            }}
            onOk={() => {
                setRetriveModal(false);
                editRetrive();
            }}
            title={"التسليم"}
            width={700}
        >
            <ProductList order={order} getOrders={getOrders}/>
        </Modal>
    );
};

const UserInfo = ({ username, phoneNumber, location }) => {
    return (
        <div
            style={{ width: "100%" }}
            className="d-flex align-items-start column-gap-5 my-3 flex-wrap justify-content-center row-gap-2" // Adjusted for column direction
        >
            <div>
                <span className="d-flex gap-2">
                    <strong>اسم المستخدم</strong>

                    {username}
                </span>
            </div>
            <div>
                <span className="d-flex gap-2">
                    <strong>رقم الهاتف</strong>

                    {phoneNumber}
                </span>
            </div>
            <div>
                <span className="d-flex gap-2">
                    <strong>العنوان</strong> {location}
                </span>
            </div>
        </div>
    );
};

const ProductList = ({ order, getOrders }) => {
    return (
        <div className="container">
            <div className="row">
                {order?.products?.map((product) => (
                    <ProductItem product={product} order={order} getOrders={getOrders} />
                ))}
            </div>
        </div>
    );
};

const ProductItem = ({ product, order, getOrders }) => {
    const [isChecked, setIsChecked] = useState( product?.isDeliverd );
    
    const deliverProduct = async (productId) => {
        try {
            const res = await axios.post(
                `${baseUrl}/api/Cart/DeliverdProduct`,
                {
                    cartId: order?.cartId,
                    productId,
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("casherToken"),
                    },
                }
            );
console.log(res);

            setIsChecked(res?.data?.currentStatus);
            getOrders()
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="col-12 mb-3" key={product.productId}>
            <div className="d-flex justify-content-between align-items-center border p-3 shadow-sm rounded">
                <span className="fw-bold" style={{ width: "60%" }}>
                    {product?.productName}
                </span>
                <span className="fw-bold">الكمية : {product?.quantity}</span>
                <span className="fw-bold">الاجمالي : {product?.itemPrice}</span>
                {isChecked ? (
                    <Tooltip title="تم التسليم">
                        <Button
                            type="link"
                            shape="circle"
                            onClick={() =>
                                deliverProduct(product.productID)
                            }
                        >
                            <i className="fa-solid fa-truck-ramp-box fa-lg text-success"></i>
                        </Button>
                    </Tooltip>
                ) : (
                    <Tooltip title="لم تصل بعد">
                        <Button
                            type="link"
                            shape="circle"
                            onClick={() =>
                                deliverProduct(product.productID)
                            }
                        >
                            <i className="fa-solid fa-truck-ramp-box fa-lg"></i>
                        </Button>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};
