import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Spin,
  notification,
  Alert,
  Divider,
  Modal,
  Upload,
  message,
} from "antd";
import axios from "axios";
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function AddProductModal({
  baseUrl,
  ismodalOpen,
  setismodalOpen,
  isEdit,
  product,
  getProducts,
  Suppliers,
  categories,
  Stores,
}) {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
  
    if (isEdit) {
      document.title = "Edit products";
      console.log(product);
      // Assuming product.photos is an array of image IDs
      const existingFiles = product?.photos?.map((imgId, index) => ({
        uid: `-1-${index}`, // unique identifier
        name: `Image ${index + 1}`,
        status: "done",
        url: `${baseUrl}/api/Storage/GetImageById?id=${imgId}`,
        response: { id: imgId }, // Store the image ID
      }));
      setFileList(existingFiles);
      form.setFieldsValue({ photos: existingFiles });
    } else {
      document.title = "Add products";
    }
  }, []);


  function openNotificationWithIcon(type, messageText, description) {
    api[type]({
      message: messageText,
      description: description,
    });
  }

  const onFinish = (values) => {
    console.log("Received values:", values);

    // Extract image IDs from uploaded files
    values.photos = fileList;

    isEdit ? editProduct(values) : createProduct(values);
  };

  async function createProduct(values) {
    setLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/api/Products`, values, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
        },
      });
      openNotificationWithIcon(
        "success",
        "Added Successfully",
        "The new product has been added successfully"
      );
      setLoading(false);
      console.log(response);
      setismodalOpen(false);
      form.resetFields();
      setFileList([]); // Reset fileList after successful submission
      getProducts();
    } catch (error) {
      setLoading(false);
      console.log(error);
      setErrorMessage(error?.response?.data[0]);
    }
  }

  async function editProduct(values) {
    setLoading(true);
    values.id = product?.id;
    try {
      const response = await axios.put(`${baseUrl}/api/Products`, values, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
        },
      });
      openNotificationWithIcon(
        "success",
        "Edited Successfully",
        "The product has been edited successfully"
      );
      setLoading(false);
      console.log(response);
      setismodalOpen(false);
      form.resetFields();
      setFileList([]); // Optionally, set to existing files
      getProducts();
    } catch (error) {
      setLoading(false);
      console.log("Edit Error:", error);
      setErrorMessage(error?.response?.data[0]);
    }
  }

  const validateProductName = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    if (value.charAt(0) === " ") {
      return Promise.reject(new Error("First character should not be a space"));
    }
    if (value.length === 1) {
      return Promise.reject(new Error("Can't be single character"));
    }
    if (/\s\s/.test(value)) {
      return Promise.reject(new Error("Consecutive spaces are not allowed"));
    }
    return Promise.resolve();
  };

  const validateUpload = () => {
    if (fileList.length === 0) {
      return Promise.reject(new Error("Please upload at least one image!"));
    }
    return Promise.resolve();
  };
  const handleRemove = (file) => {
    console.log(file);
    
  };
  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpload = async ({ file, onSuccess, onError, onProgress }) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${baseUrl}/api/Storage/UploadImage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
        },
        onUploadProgress: ({ total, loaded }) => {
          onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
        },
      });

      const imgId = response.data.id; // Adjust according to your API response
      file.url = `${baseUrl}/api/Storage/GetImageById?id=${imgId}`;
      file.response = { id: imgId };
      onSuccess(response.data,'file' ,file);
      
      setFileList((prevList) => prevList && prevList?.length !== 0 ? [...prevList, file?.response?.id] : [file?.response?.id]);
    } catch (error) {
      console.error("Upload failed:", error);
      onError(error);
      message.error(`${file.name} upload failed.`);
    }
  };



  return (
    <>
      {contextHolder}

      {loading && <Spin spinning={true} fullscreen />}

      <Modal
        open={ismodalOpen}
        onCancel={() => {
          setismodalOpen(false);
          setLoading(false);
        }}
        width={800}
        footer={false}
        centered
      >
        <Form
          size="large"
          colon={false}
          requiredMark={false}
          form={form}
          onFinish={onFinish}
          initialValues={isEdit ? product : {}}
        >
          <div className="text-center">
            <h2 className="fw-bold mb-4">
              <span className="text-red">أضف </span>منتج
            </h2>
          </div>

          <div className="container">
            <Form.Item
              label="صور المنتج"
              name="photos"
              valuePropName="fileList"
              getValueFromEvent={normFile}
           
            >
              <Upload
                name="photos"
                listType="picture"
                multiple
                customRequest={handleUpload}
                onRemove={handleRemove}
                fileList={fileList}
                beforeUpload={(file) => {
                  const isValidType = ["image/jpeg", "image/jpg", "image/png"].includes(file.type);
                  if (!isValidType) {
                    message.error("You can only upload JPG/PNG file!");
                  }
                  return isValidType || Upload.LIST_IGNORE;
                }}
              >
                <Button icon={<UploadOutlined />}>Upload Photos</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              label="اسم المنتج"
              name="name"
              rules={[
                { required: true, message: "قم بأدخال اسم المنتج" },
                { validator: validateProductName },
              ]}
            >
              <Input size="large" />
            </Form.Item>

            <div className="row">
              <div className="col-6">
                <Form.Item
                  label="المورد"
                  name="supplierID"
                  rules={[{ required: true, message: "قم بأختيار المورد" }]}
                >
                  <Select className="w-100" size="large" placeholder="اختر المورد">
                    {Suppliers?.map((supplier) => (
                      <Option key={supplier.id} value={supplier.id}>
                        {supplier.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-6">
                <Form.Item
                  label="الفئه"
                  name="categoryID"
                  rules={[{ required: true, message: "قم بأدخال فئة المنتج" }]}
                >
                  <Select
                    className="w-100"
                    size="large"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder="اختر الفئة"
                  >
                    {categories?.map((category) => (
                      <Option key={category.code} value={category.code}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <Form.Item
                  label="سعر البيع"
                  name="sellingPrice"
                  rules={[{ required: true, message: "قم بأدخال سعر البيع" }]}
                >
                  <InputNumber
                    min={1}
                    className="w-100"
                    size="large"
                    controls={false}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  label="سعر الشراء"
                  name="buyingPrice"
                  rules={[{ required: true, message: "قم بأدخال سعر الشراء" }]}
                >
                  <InputNumber
                    min={1}
                    className="w-100"
                    size="large"
                    controls={false}
                  />
                </Form.Item>
              </div>
            </div>

            <Divider className="mt-0" />

            <Form.List
              name="productStores"
              initialValue={
                isEdit
                  ? product?.stores
                  : [{}]
              }
            >
              {(fields, { add, remove }) => (
                <>
                  {fields?.map(({ key, name, ...restField }) => (
                    <div className="row ">
                      <h6 className="mb-3">مخزن {key + 1}:</h6>
                      <div className="col-6 ">
                        <Form.Item
                          label="اسم المخزن"
                          name={[name, "storeID"]}
                          rules={[
                            { required: true, message: "قم بأدخال اسم المخزن" },
                          ]}
                        >
                          <Select className="w-100" size="large">
                            {Stores?.map((color) => (
                              <Option key={color.code} value={color.code}>
                                {color.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-6 ">
                        <Form.Item
                          label="الكميه"
                          name={[name, "amount"]}
                          rules={[
                            { required: true, message: "قم بأدخال الكميه " },
                          ]}
                        >
                          <InputNumber
                            min={1}
                            className="w-100"
                            size="large"
                            controls={false}
                          />
                        </Form.Item>
                      </div>
                      {key === 0 ? (
                        ""
                      ) : (
                        <div className="text-end w-100 d-flex justify-content-end">
                          <Button
                            type="link"
                            danger
                            className="d-flex justify-content-center align-items-center mb-3 "
                            size=""
                            onClick={() => remove(name)}
                          >
                            <MinusCircleOutlined />
                            حذف المخزن
                          </Button>
                        </div>
                      )}
                      {fields.length === key + 1 ? "" : <Divider />}
                    </div>
                  ))}
                  <Form.Item className="text-center">
                    <Button
                      type=""
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                      className="w-50 mt-2"
                    >
                      أضف مخزن اخر
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            

            {errorMessage && (
              <Alert type="error" message={errorMessage}></Alert>
            )}

            <div className="d-flex justify-content-between align-items-center">
              <div className="col-6">
                <Form.Item label="الكود" name="barCode">
                  <Input min={1} className="w-100" size="large" />
                </Form.Item>
              </div>
              <Form.Item className="text-start">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="mt-2 btn-blue"
                >
                  {isEdit ? "عدل" : "أضف المنتج"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
}
