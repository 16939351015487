import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Skeleton,
  Pagination,
  Input,
  FloatButton,
  Select,
} from "antd";
import useDocumentTitle from "../../Customized Hooks/useDocumentTitle";

export default function Products({ baseUrl }) {
  useDocumentTitle("Products");
  const [current, setCurrent] = useState(1);
  const [numofPages, setnumofPages] = useState();
  const [Products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [catagories, setCatagories] = useState();
  const [suppliers, setSuppliers] = useState();
  const [supplierCode, setSupplierCode] = useState( null);
  const [catogeryCode, setcatogeryCode] = useState( null);
  const [name, setName] = useState(localStorage.getItem("name") ? localStorage.getItem("name") :'');
 
  useEffect(() => {
    getCatagories();
    getSuppliers();
      SearchByNameAndFilter();
  }, [current]);


  async function getCatagories() {
    setLoading(true);
    await axios
      .get(`${baseUrl}/api/Categories/GetAll`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("casherToken"),
        },
      })
      .then((response) => {
        setLoading(false);
        console.log("categories res", response);
        console.log("categories", response.data.categories);

        setCatagories(response.data.categories);
        console.log(catagories);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  async function getSuppliers() {
    setLoading(true);
    await axios
      .get(`${baseUrl}/api/Suppliers/GetAll`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("casherToken"),
        },
      })
      .then((response) => {
        setLoading(false);
        console.log("supplier res", response);
        console.log("suppliers", response.data.suppliers);
        setSuppliers(response.data.suppliers);
        console.log(` supplier : ${suppliers}`);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }


  function PaginationChange(e) {
    setCurrent(e);
    SearchByNameAndFilter(name, catogeryCode, supplierCode);
  }


  async function SearchByNameAndFilter(reset) {
    setLoading(true);
    let url = reset ?
      `${baseUrl}/api/Products/SearchProduct?PageNumber=${current}&PageSize=20`
      :
      `${baseUrl}/api/Products/SearchProduct?PageNumber=${current}&PageSize=20
    &Name=${name}
    ${catogeryCode ? `&CategoryID=${catogeryCode}` : ""}
    ${supplierCode ? `&SupplierID=${supplierCode}` : ""}`;

    console.log("Constructed URL:", url);

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("casherToken"),
        },
      });
      setLoading(false);

      if (response.data && response.data.products) {
        console.log("Filtered products:", response.data);
        setProducts(response.data.products);
        setnumofPages(response?.data?.totalPages);
        if (current > response?.data?.totalPages && response?.data?.totalPages !== 0) {
          setCurrent(response?.data?.totalPages)
        }
      } else {
        console.error("No products found for the given search criteria.");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }


  function handleClear() {
    setcatogeryCode(null);
    setSupplierCode(null);
    setName("");
    localStorage.removeItem("name");
    localStorage.removeItem("catogeryCode");
    localStorage.removeItem("supplierCode");
    SearchByNameAndFilter();
  }

  return (
    <>
      <div className="container-fluid d-flex justify-content-center align-items-center">
        <div className="mx-auto  w-75 ">
          <div className="d-flex justify-content-between ms-auto my-3">
            <div className="w-75 d-flex gap-2 h-20">
              <Input
                size="large"
                prefix={<i className="fa fa-search text-secondary " />}
                placeholder={"اسم منتج"}
                className=""
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
              />
              <Select
                placeholder={"المورد"}
                optionFilterProp="label" // Use "label" for filtering
                options={suppliers?.map((item) => ({
                  label: item.name,
                  value: item.id ? item.id : item.code,
                }))}
                className="custom-dropdown w-100 h-100"
                showSearch // Enable search functionality
                onChange={(e) => {
                  setSupplierCode(e);
                }} // Handle select change
                allowClear
                onClear={() => setSupplierCode("")}
                value={supplierCode}
              />
              <Select
                placeholder={"الفئة"}
                optionFilterProp="label" // Use "label" for filtering
                options={catagories?.map((item) => ({
                  label: item.name,
                  value: item.id ? item.id : item.code,
                }))}
                className="custom-dropdown w-100 h-100"
                showSearch // Enable search functionality
                onChange={(e) => {
                  setcatogeryCode(e);
                }} // Handle select change
                allowClear
                onClear={() => setcatogeryCode("")} 
                value={catogeryCode}
              />
              <button
                className="btn  btn-primary rounded-5   px-3 py-2"
                onClick={() => SearchByNameAndFilter()}
              >
                بحث
              </button>
            </div>
            <button
              onClick={() => {
                handleClear();
              }}
              className="btn btn-red rounded-5  px-3 py-2"
            >
              <i className="fa fa-trash"></i> مسح البحث
            </button>
          </div>
          <Pagination
            current={current}
            onChange={PaginationChange}
            pageSize={5}
            total={numofPages ? numofPages * 5 : 0}
            showSizeChanger={false}
            className="mt-4 mb-2"
          />
          <div className=" overflow-x-auto">
            <table className="table table-bordered custom-table ">
              <thead>
                <tr className="">
                  <th className="text-center"> أسم المنتج</th>
                  <th className="text-center">المورد</th>
                  <th className="text-center"> الكميه</th>
                  <th className="text-center"> سعر البيع</th>
                  <th className="text-center">سعر الشراء</th>
                  <th className="text-center">الكود</th>
                </tr>
              </thead>
              {loading ? (
                <></>
              ) : (
                <>
                  <tbody>
                    {Products?.map((Product, index) => (
                      <tr key={index} className="">
                        <td className="">
                          <h6 className=" font-inter "> {Product.name}</h6>
                        </td>

                        <td className="text-center font-inter">
                          {Product.supplierName}
                        </td>
                        <td className="text-center font-inter">
                          {Product.amount}
                        </td>
                        <td className="text-center font-inter">
                          {Product.sellingPrice}
                        </td>
                        <td className="text-center font-inter">
                          {Product?.buyingPrice}
                        </td>
                        <td className="text-center font-inter">
                          {Product.barCode}
                        </td>
                       
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </table>
          </div>
          {loading ? (
            <>
              <div className="container-lg mt-2">
                <div className="row">
                  <div className="col-3">
                    <Skeleton
                      active
                      loading={true}
                      paragraph={{
                        rows: 3,
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <Skeleton
                      active
                      loading={true}
                      paragraph={{
                        rows: 3,
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <Skeleton
                      active
                      loading={true}
                      paragraph={{
                        rows: 3,
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <Skeleton
                      active
                      loading={true}
                      paragraph={{
                        rows: 3,
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
      
         
        </div>
      </div>
    
      <FloatButton.Group type="primary" >
      <FloatButton.BackTop type="primary" />
        </FloatButton.Group>

    </>
  );
}
