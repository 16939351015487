import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Modal, Pagination, Skeleton, notification, message, Popconfirm, Button, Input } from "antd";
import useDocumentTitle from '../../Customized Hooks/useDocumentTitle';

export default function Technicians({ baseUrl }) {
    useDocumentTitle('الفنيين')
    const [Phone, setphone] = useState('');
    const [current, setCurrent] = useState(1);
    const [numofPages, setnumofPages] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [Technicians, setTechnicians] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpenForEdit, setIsModalOpenForEdit] = useState(false);
    const [TechnicianId, setTechniciansId] = useState(null);
    const [isModalOpenForAdd, setIsModalOpenForAdd] = useState(false);
    const [TechnicianName, setTechnicianName] = useState('');
    const [errorName, setErrorName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [sending, setsending] = useState(false);
    useEffect(() => {
        getTechnicians()
    }, [current]);

    async function getTechnicians() {
        setLoading(true)
        await axios.get(`${baseUrl}/api/Technicians/GetAll`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setLoading(false)
                console.log('technicians res', response);
                console.log('Technicians', response.data.technicians);
                setTechnicians(response.data.technicians);
                // setnumofPages(response.data.paginationResult.numberOfPages)
                // if (response.data.paginationResult.numberOfPages < current) {
                //     setCurrent(pre => pre - 1)
                // }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error);

            })
    }


    async function AddTechnician(name, phone) {
        setsending(true)
        let data = {
            "name": name,
            "phone": phone
        }


        await axios.post(`${baseUrl}/api/Technicians`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                console.log('Technician', response);
                setsending(false)
                handleAddCancel()
                openNotificationWithIcon(
                    'success',
                    "تم بنجاج",
                    "تمت اضافة فني بنجاح."
                );
                getTechnicians();
            })
            .catch((error) => {
                setsending(false)
                console.log(error);

            })
    }

    async function EditTechnician(name, phone) {
        setsending(true)

        let data = {
            "name": name,
            "phone": phone,
            'code': TechnicianId
        }
        await axios.put(`${baseUrl}/api/Technicians`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setsending(false)

                handleEditCancel()
                setErrorName(null);
                console.log('edit Technician response : ', response);
                setTechnicians(response.data.TechnicianLists);
                openNotificationWithIcon(
                    "success",
                    "تم التعديل",
                    "تم تعديل بيانات الفني بنجاح"
                );
                getTechnicians()
            }).catch((error) => {
                setsending(false)

                console.log(error);

            })
    }

    const showEditModal = (id, name) => {
        console.log(id, name);
        setTechniciansId(id)
        setTechnicianName(name)
        setIsModalOpenForEdit(true)
    };

    const handleEditOk = () => {
        const trimmedTechnicianName = TechnicianName.trim();
        const trimmedphone = Phone.trim();

        if (trimmedTechnicianName === "" || trimmedTechnicianName === null) {
            setErrorName("من فضلك ادخل اسم الفني !");
            return;
        }

        if (trimmedphone === "" || trimmedphone === null) {
            setErrorName("من فضلك ادخل رقم الفني !");
            return;
        }

        else if (!trimmedphone.match('^01[0-2,5]{1}[0-9]{8}$')) {
            setErrorName("تأكد من صحة الرقم");
            return;
        }

        else {
            EditTechnician(trimmedTechnicianName, trimmedphone);
            setErrorName(null);

        }
    };

    const handleEditCancel = () => {
        setTechnicianName('');
        setphone('');
        setphone('');
        setTechniciansId(null);
        setErrorName(null);
        setIsModalOpenForEdit(false);
    };


    const showAddModal = () => {
        setIsModalOpenForAdd(true);
    };

    function PaginationChange(e) {
        setCurrent(e)
    }

    const handleAddOk = () => {
        const trimmedTechnicianName = TechnicianName.trim();
        const trimmedphone = Phone.trim();

        if (trimmedTechnicianName === "" || trimmedTechnicianName === null) {
            setErrorName("من فضلك ادخل اسم الفني !");
            return;
        }

        if (trimmedphone === "" || trimmedphone === null) {
            setErrorName("من فضلك ادخل رقم الفني !");
            return;
        }

        else if (!trimmedphone.match('^01[0-2,5]{1}[0-9]{8}$')) {
            setErrorName("تأكد من صحة الرقم");
            return;
        }

        else {
            AddTechnician(trimmedTechnicianName, trimmedphone);
            setErrorName(null);

        }
    };


    const handleAddCancel = () => {
        setTechnicianName('');
        setphone('');
        setErrorName(null);
        setIsModalOpenForAdd(false);
    };


    async function DeleteTechnician(TechnicianId) {

        await axios.delete(`${baseUrl}/api/Technicians?Code=${TechnicianId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                console.log('Technician', response);
                openNotificationWithIcon(
                    "success",
                    "تم الحذف",
                    "تم حذف الفني بنجاح"
                );
                getTechnicians()
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage(error?.response?.data?.message)
            })
    }

    function openNotificationWithIcon(type, message, description) {
        api[type]({
            message: message,
            description: description,
        });
    }

    const cancel = (e) => {
        console.log(e);
        message.error("Click on No");
    };

    return <>
        {contextHolder}
        <div className='container-fluid d-flex justify-content-center align-items-center'>


            <div className="mx-auto py-4 w-75" >
                <div className="d-flex justify-content-between ms-auto my-3">
                    <div className='w-50'>
                        <Input className='rounded-5' size='large' placeholder='ابحث عن فني' prefix={<i className='fa fa-search text-secondary' />} />
                    </div>
                    <button onClick={showAddModal} className="btn btn-red rounded-5   px-3 py-2"><i className="fa-solid fa-plus"></i> أضف فني</button>
                </div>
                {loading ? (
                    <>
                        <div>
                            <table className="table table-bordered custom-table">
                                <thead>
                                    <tr className=''>
                                        <th className=''> أسم الفني</th>
                                        <th className=''> رقم الهاتف</th>
                                        <th className='text-center  col-2'>حذف / تعديل</th>
                                    </tr>
                                </thead>
                            </table>
                            <div className="row">
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Skeleton
                                        active
                                        loading={true}
                                        paragraph={{
                                            rows: 3,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) :
                    (
                        <div className="d-flex justify-content-center align-items-center">
                            <table className="table table-bordered custom-table">
                                <thead>
                                    <tr className=''>
                                        <th className=''> أسم الفني</th>
                                        <th className=''> رقم الهاتف</th>
                                        <th className='text-center  col-2'>حذف / تعديل</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {Technicians?.map((Technician, index) => (
                                        <tr key={index} className=''>
                                            <td className=''>
                                                <h6>{Technician.name}</h6>
                                            </td>
                                            <td className=''>
                                                <h6>{Technician.phone}</h6>
                                            </td>

                                            <td className='text-center col-2'>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="حذف هذا الفني"
                                                    description="هل ان بالتأكيد تريد حذف هذا الفني ؟"
                                                    onConfirm={() => DeleteTechnician(Technician.code)}
                                                    onCancel={cancel}
                                                    okText="نعم"
                                                    cancelText="لا"
                                                >
                                                    <Button type="link" className="" danger>
                                                        <i className='fa fa-trash fa-lg' />
                                                    </Button>
                                                </Popconfirm>
                                                <Button type='link' onClick={() => showEditModal(Technician.code, Technician.name)}><i className='fa fa-edit fa-lg' /></Button>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                <div className='d-flex justify-content-end'>

                    <Pagination current={current} onChange={PaginationChange} pageSize={5} total={numofPages ? numofPages * 5 : 0} showSizeChanger={false} />
                </div>
                {errorMessage !== null && <div className='d-flex justify-content-center'>
                    <Alert className='' closable type='error' message={errorMessage}></Alert>
                </div>}
            </div>

        </div>


        <Modal title={<h5 className=' fw-bold'><i className='fa fa-edit text-primary' /> تعديل بيانات الفني</h5>} open={isModalOpenForEdit} onOk={handleEditOk} confirmLoading={sending} onCancel={handleEditCancel} okText="تعديل">
            <div className='my-4'>
                <label htmlFor="TechnicianNameInput" className='fw-bold'>أسم الفني</label>
                <input
                    type="text"
                    className="form-control mb-3 mt-2"
                    id="TechnicianNameInput"
                    name="TechnicianName"
                    value={TechnicianName}
                    onChange={(e) => setTechnicianName(e.target.value)}
                    placeholder='أدخل أسم الفني'
                />
                <label htmlFor="phone" className='fw-bold'>رقم الهاتف</label>

                <input
                    type="text"
                    className="form-control mb-3 mt-2"
                    id="phone"
                    name="TechnicianName"
                    value={Phone}
                    onChange={(e) => setphone(e.target.value)}
                    placeholder="أدخل رقم الهاتف"
                />
            </div>
            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" />
            )}
        </Modal>




        <Modal title={<h5 className='font-inter fw-bold'>أضافة فني</h5>} open={isModalOpenForAdd} confirmLoading={sending} onOk={handleAddOk} onCancel={handleAddCancel} okText="أضافه" cancelText='الغاء' >
            <div className='my-4'>
                <label htmlFor="TechnicianNameInput" className='fw-bold'>أسم الفني</label>
                <input
                    type="text"
                    className="form-control mb-3 mt-2"
                    id="TechnicianNameInput"
                    name="TechnicianName"
                    value={TechnicianName}
                    onChange={(e) => setTechnicianName(e.target.value)}
                    placeholder='أدخل أسم الفني'
                />
                <label htmlFor="phone" className='fw-bold'>رقم الهاتف</label>

                <input
                    type="text"
                    className="form-control mb-3 mt-2"
                    id="phone"
                    name="TechnicianName"
                    value={Phone}
                    onChange={(e) => setphone(e.target.value)}
                    placeholder="أدخل رقم الهاتف"
                />
            </div>
            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" className='mt-2' />
            )}
        </Modal>
    </>

} 