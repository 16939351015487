// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
    .container {
        margin: 0 auto !important;
        padding: 0 !important;
    }

    .table {
        margin-bottom: 35px !important;
    }

    ul {
        margin-top: 45px !important;
        padding-left: 20px !important;
    }

 
}`, "",{"version":3,"sources":["webpack://./src/Components/Casher/Orders/PrintOrder.css"],"names":[],"mappings":"AAAA;IACI;QACI,yBAAyB;QACzB,qBAAqB;IACzB;;IAEA;QACI,8BAA8B;IAClC;;IAEA;QACI,2BAA2B;QAC3B,6BAA6B;IACjC;;;AAGJ","sourcesContent":["@media print {\n    .container {\n        margin: 0 auto !important;\n        padding: 0 !important;\n    }\n\n    .table {\n        margin-bottom: 35px !important;\n    }\n\n    ul {\n        margin-top: 45px !important;\n        padding-left: 20px !important;\n    }\n\n \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
