import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Alert, Modal, InputNumber, Pagination, Skeleton, notification, message, Popconfirm, Button, Select, Input } from "antd";
import useDocumentTitle from '../../Customized Hooks/useDocumentTitle';

export default function Coupons({ baseUrl }) {
    useDocumentTitle('كوبونات');
    const [current, setCurrent] = useState(1);
    const [sending, setsending] = useState(false);
    const [couponID, setCouponID] = useState();
    const [supplierID, setsupplierID] = useState();
    const [value, setValue] = useState(1);
    const [maxValue, setmaxValue] = useState(1);
    const [numofPages, setnumofPages] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [coupons, setCoupons] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpenForEdit, setIsModalOpenForEdit] = useState(false);
    const [isModalOpenForAdd, setIsModalOpenForAdd] = useState(false);
    const [errorName, setErrorName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        getCoupons();
        getSuppliers();
    }, [current]);

    async function getCoupons() {
        setLoading(true);
        await axios.get(`${baseUrl}/api/Coupon/GetAllCoupons`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setLoading(false);
                setCoupons(response.data);
                setnumofPages(response.data.paginationResult.numberOfPages);
                if (response.data.paginationResult.numberOfPages < current) {
                    setCurrent(pre => pre - 1);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    async function getSuppliers() {
        await axios.get(`${baseUrl}/api/Suppliers/GetAll`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setSuppliers(response.data.suppliers);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function PaginationChange(e) {
        setCurrent(e);
    }

    async function AddCoupon() {
        setsending(true);
        let data = {
            "supplierID": supplierID,
            "value": value,
            "maxValue": maxValue
        };

        await axios.post(`${baseUrl}/api/Coupon/AddCouponToSupplier`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setsending(false);
                handleAddCancel();
                openNotificationWithIcon("success", "تمت الإضافة بنجاح", "تمت إضافة الكوبون بنجاح.");
                getCoupons();
            })
            .catch((error) => {
                setsending(false);
                console.log(error);
                setErrorName('حدث خطأ أثناء إضافة الكوبون');
            });
    }

    async function EditCoupon() {
        setsending(true);
        let data = {
            "couponID": couponID,
            "value": value,
            "maxValue": maxValue
        };

        await axios.put(`${baseUrl}/api/Coupon/EditCouponToSupplier`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                setsending(false);
                handleEditCancel();
                openNotificationWithIcon("success", "تم التعديل بنجاح", "تم تعديل الكوبون بنجاح.");
                getCoupons();
            })
            .catch((error) => {
                setsending(false);
                console.log(error);
                setErrorName('حدث خطأ أثناء تعديل الكوبون');
            });
    }

    async function DeleteCoupon(couponID) {
        await axios.delete(`${baseUrl}/api/Coupon?ID=${couponID}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
            }
        })
            .then((response) => {
                openNotificationWithIcon("success", "تم الحذف بنجاح", "تم حذف الكوبون بنجاح.");
                getCoupons();
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage(error?.response?.data?.message);
            });
    }

    function openNotificationWithIcon(type, message, description) {
        api[type]({
            message: message,
            description: description,
        });
    }

    const cancel = (e) => {
        message.error("تم إلغاء العملية");
    };

    const showEditModal = (coupon) => {
        setCouponID(coupon.couponID);
        setsupplierID(coupon.supplierID);
        setValue(coupon.value);
        setmaxValue(coupon.maxValue);
        setIsModalOpenForEdit(true);
    };

    const handleEditOk = () => {
        if (!supplierID || value <= 0 || maxValue <= 0) {
            setErrorName("يرجى ملء جميع الحقول بشكل صحيح.");
            return;
        }
        EditCoupon();
    };

    const handleEditCancel = () => {
        setCouponID(null);
        setsupplierID(null);
        setValue(0);
        setmaxValue(0);
        setErrorName(null);
        setIsModalOpenForEdit(false);
    };

    const showAddModal = () => {
        setIsModalOpenForAdd(true);
    };

    const handleAddOk = () => {
        if (!supplierID || value <= 0 || maxValue <= 0) {
            setErrorName("يرجى ملء جميع الحقول بشكل صحيح.");
            return;
        }
        AddCoupon();
    };

    const handleAddCancel = () => {
        setsupplierID(null);
        setValue(0);
        setmaxValue(0);
        setErrorName(null);
        setIsModalOpenForAdd(false);
    };

    return <>
        {contextHolder}
        <div className='container-fluid d-flex justify-content-center' dir='rtl'>
            <div className="mx-auto py-4 w-75">
                <div className="d-flex justify-content-between ms-auto my-3">
                    <div className='w-50'>
                        <Input className='rounded-5' size='large' placeholder='ابحث عن كوبون' prefix={<i className='fa fa-search text-secondary' />} />
                    </div>
                    <button onClick={showAddModal} className="btn btn-red rounded-5 px-3 py-2"><i className="fa-solid fa-plus"></i> إضافة كوبون</button>
                </div>
                <div className='d-flex justify-content-center'>
                    <table className="table table-bordered custom-table">
                        <thead>
                            <tr>
                                <th>المورد</th>
                                <th>القيمة</th>
                                <th>القيمة القصوى</th>
                                <th className='text-center col-2'>حذف / تعديل</th>
                            </tr>
                        </thead>
                        {loading ? (
                            <></>
                        ) : (
                            <tbody>
                                {coupons?.map((coupon, index) => (
                                    <tr key={index}>
                                        <td>{suppliers.find(s => s.id === coupon.supplierID)?.name || 'غير معروف'}</td>
                                        <td>{coupon.value}%</td>
                                        <td>{coupon.maxValue}</td>
                                        <td className='text-center'>
                                            {/* <Popconfirm
                                                placement="topRight"
                                                title="حذف هذا الكوبون"
                                                description="هل أنت متأكد أنك تريد حذف هذا الكوبون؟"
                                                onConfirm={() => DeleteCoupon(coupon.couponID)}
                                                onCancel={cancel}
                                                okText="نعم"
                                                cancelText="لا"
                                            >
                                                <Button type="link" danger>
                                                    <i className='fa fa-trash fa-lg' />
                                                </Button>
                                            </Popconfirm> */}
                                            <Button type='link' onClick={() => showEditModal(coupon)}>
                                                <i className='fa fa-edit fa-lg' />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
                {loading ? (
                    <div className="container-lg">
                        <div className="row">
                            <div className="col-3"><Skeleton active loading={true} paragraph={{ rows: 3 }} /></div>
                            <div className="col-3"><Skeleton active loading={true} paragraph={{ rows: 3 }} /></div>
                            <div className="col-3"><Skeleton active loading={true} paragraph={{ rows: 3 }} /></div>
                            <div className="col-3"><Skeleton active loading={true} paragraph={{ rows: 3 }} /></div>
                        </div>
                    </div>
                ) : (
                    <div className='d-flex justify-content-end'>
                        <Pagination
                            current={current}
                            onChange={PaginationChange}
                            total={numofPages * 10}
                            showSizeChanger={false}
                            pageSize={10}
                        />
                    </div>
                )}
            </div>
        </div>
        <Modal
            title="تعديل كوبون"
            visible={isModalOpenForEdit}
            onOk={handleEditOk}
            onCancel={handleEditCancel}
            confirmLoading={sending}
            okText="حفظ"
            cancelText="إلغاء"
        >
            {errorName && <Alert message={errorName} type="error" showIcon className="mb-3" />}
            <Select
                placeholder="اختر المورد"
                size="large"
                value={supplierID}
                onChange={setsupplierID}
                className='mb-3 w-100'
                options={suppliers.map(supplier => ({ label: supplier.name, value: supplier.id }))}
            />
            <InputNumber
                placeholder="القيمة (%)"
                size="large"
                min={1}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace('%', '')}
                value={value}
                onChange={setValue}
                className='mb-3 w-100'
            />
            <InputNumber
                placeholder="القيمة القصوى"
                size="large"
                min={1}
                value={maxValue}
                onChange={setmaxValue}
                className='mb-3 w-100'
            />
        </Modal>
        <Modal
            title="إضافة كوبون"
            visible={isModalOpenForAdd}
            onOk={handleAddOk}
            onCancel={handleAddCancel}
            confirmLoading={sending}
            okText="إضافة"
            cancelText="إلغاء"
        >
            {errorName && <Alert message={errorName} type="error" showIcon className="mb-3" />}
            <Select
                placeholder="اختر المورد"
                size="large"
                value={supplierID}
                onChange={setsupplierID}
                className='mb-3 w-100'
                options={suppliers.map(supplier => ({ label: supplier.name, value: supplier.id }))}
            />
            <InputNumber
                placeholder="القيمة (%)"
                size="large"
                min={1}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace('%', '')}
                value={value}
                onChange={setValue}
                className='mb-3 w-100'
            />
            <InputNumber
                placeholder="القيمة القصوى"
                size="large"
                min={1}
                value={maxValue}
                onChange={setmaxValue}
                className='mb-3 w-100'
            />
        </Modal>
    </>;
}
